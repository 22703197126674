import { FC } from 'react';

import { CardShell } from '@/common/components/Card';
import { Box, Stack } from '@/common/components/Display';
import { useTheme } from '@/theme';

interface Props {
  errorMessage?: string;
}

export const FrontCardErrored: FC<Props> = ({ errorMessage }) => {
  const theme = useTheme();
  return (
    <CardShell>
      <Box
        p={'2rem 1rem'}
        ta={'center'}
        fz={theme.fontSizes.lg}
        bg={'#f9f9f9'}
        c={'#6d7278'}
      >
        <Stack gap={0} ta={'center'} p={'0 1rem'} align="center">
          <img
            src="/assets/images/icon-light-error.svg"
            alt="Error"
            style={{ marginBottom: '0.25rem' }}
          />
          <Box mb={'0.25rem'} fz={'1.25rem'} fw={600}>
            {errorMessage || 'Something went wrong'}
          </Box>
          <Box lts={0} fz={theme.fontSizes.md}>
            You may try refreshing the page or try again later
          </Box>
        </Stack>
      </Box>
    </CardShell>
  );
};
