import isNil from 'lodash/isNil';
import isNumber from 'lodash/isNumber';

/**
 * @param value the string that contains a floating-point number.
 * @returns the floating-point as a number or undefined.
 */
export const asFloatOrUndefined = (value: string) =>
  asFloatOrDefault(value, undefined);

/**
 * @param value the string that contains a floating-point number.
 * @param defaultValue the default value if not a number.
 * @returns the floating-point as a number.
 */
export const asFloatOrDefault = (
  value: string,
  defaultValue: number | undefined
): number => {
  if (isNil(value)) {
    return defaultValue;
  }

  if (typeof value === 'number') {
    return value as number;
  }
  try {
    return parseFloat(value.trim());
  } catch {
    return defaultValue;
  }
};

/**
 * Returns the value as a number. Similar to asNumber but doesn't default to zero.
 * @param value The value to be converted to a number.
 * @param defaultValue the default value if not a number.
 */
export const asNumberOrDefault = (
  value: number | string | undefined,
  defaultValue: number | undefined
): number => {
  if (isNil(value)) {
    return defaultValue;
  }
  if (isNumber(value)) {
    return isNaN(value as number) ? defaultValue : (value as number);
  }
  try {
    const isNegative = (value as string).startsWith('-');
    const cleaned = (value as string).replace(/[^0-9|-]*/gi, '');
    if (!cleaned) {
      return defaultValue;
    }
    const parsed = parseInt(isNegative ? `-${cleaned}` : cleaned, 10);
    return isNaN(parsed) ? defaultValue : parsed;
  } catch {}
  return defaultValue;
};

/**
 * Returns the value as a number or undefined if not a valid number.
 *
 * @param value The value to be converted to a number.
 */
export const asNumberOrUndefined = (
  value: number | string | undefined
): number => {
  return asNumberOrDefault(value, undefined);
};

/**
 * Returns the value as a number.
 *
 * @param value The value to be converted to a number.
 * @param defaultValue the default value if not a number, default is 0.
 */
export const asNumber = (
  value: number | string | undefined,
  defaultValue: number | undefined = 0
): number => {
  return asNumberOrDefault(value, defaultValue);
};

/**
 * Returns the value as a float.
 *
 * @param value The value to be converted to a number.
 * @param defaultValue the default value if not a number, default is 0.
 */
export const asFloat = (
  value: number | string | undefined,
  defaultValue: number | undefined = 0
): number => {
  if (isNil(value)) {
    return defaultValue;
  }
  if (isNumber(value)) {
    return isNaN(value as number) ? defaultValue : (value as number);
  }
  try {
    const cleaned = (value as string).replace(/[^0-9-.,]*/gi, '');
    if (!cleaned) {
      return defaultValue;
    }
    const parsed = parseFloat(cleaned);
    return isNaN(parsed) ? defaultValue : parsed;
  } catch {}
  return defaultValue;
};

/**
 * Returns the ordinal of the number (1 -> 1st, 2 -> 2nd, etc.).
 *
 * @param value The value to convert to an ordinal.
 * @returns The ordinal.
 */
export const getOrdinal = (value: number): string => {
  const s = ['th', 'st', 'nd', 'rd'];
  const v = value % 100;
  return value.toLocaleString() + (s[(v - 20) % 10] || s[v] || s[0]);
};

/**
 * Returns the ordinal position suffix of the number (1 -> st, 2 -> nd, etc.).
 *
 * @param value The value to convert to an ordinal.
 * @returns The ordinal suffix.
 */
export const getOrdinalSuffix = (value: number): string => {
  const s = ['th', 'st', 'nd', 'rd'];
  const v = value % 100;
  return s[(v - 20) % 10] || s[v] || s[0];
};

/**
 * Returns an appropriate width for the mantine number input based on its current value.
 *
 * @param value The length of the current input value.
 * @returns The width the input should be in ch.
 */
export const getNumInputLength = (value: number): number => {
  if (!isNumber(value) || isNaN(value) || value === 0) return 8;
  // get the length of the input value and then adds 7 to account for the spinner
  return Math.ceil(Math.log10(Math.abs(value) + 1)) + 7;
};

/**
 * Returns the absolute (non-negative) number. Passes through null or undefined values.
 * @param value The number value to make absolute
 */
export const asAbsolute = (
  value: number | null | undefined
): number | null | undefined => {
  if (value === null || value === undefined) {
    return value;
  }

  if (value < 0) {
    return -value;
  }

  return value;
};

/**
 * Returns whether the value is between the min and max values, inclusively.
 *
 * @param value the value to check if it's in the range.
 * @param minValue The minimum value.
 * @param maxValue The maximum value.
 */
export const isBetween = (value: number, minValue: number, maxValue: number) =>
  value >= minValue && value <= maxValue;

export const getPercent = (total: number, n: number, digits: number = 0) => {
  if (!total || !n) {
    return 0;
  }
  return Number(((n * 100) / total).toFixed(digits));
};

export const getPercentString = (
  total: number,
  n: number,
  digits: number = 0
) => {
  return `${getPercent(total, n, digits).toLocaleString()}%`;
};

export interface ICountable {
  count: number;
  [name: string]: any;
}

/**
 * Reduce an array by adding the count property of each item
 */
export const getCount = (arr: ICountable[]) =>
  arr.reduce((acc, e) => acc + e.count, 0);

export const roundTo2Decimals = (value: number) => roundTo(value, 2);

export const roundTo = (value: number, decimalPlaces: number) => {
  return isNil(value) ? value : parseFloat(value.toFixed(decimalPlaces));
};

export const toRoundedLocaleStringWithDecimals = (
  value: number,
  decimals: number
) => {
  return roundTo(value, decimals).toLocaleString(undefined, {
    minimumFractionDigits: decimals,
    maximumFractionDigits: decimals
  });
};

export const toAudCurrencyDisplay = (value: number) => {
  const valueToDisplay = !value ? 0 : value;
  return valueToDisplay.toLocaleString(undefined, {
    style: 'currency',
    currency: 'AUD',
    compactDisplay: 'short'
  });
};

/**
 * To localised compact number display.
 * @example 1234 -> 1.2k
 */
export const toCompactDisplay = (value: number) => {
  const valueToDisplay = !value ? 0 : value;
  return valueToDisplay.toLocaleString(undefined, {
    notation: 'compact',
    compactDisplay: 'short'
  });
};

/**
 * Safe divide: numerator / denominator
 * @param numerator
 * @param denominator
 */
export const safeDivide = (numerator: number, denominator: number) => {
  if (
    !isNumber(denominator) ||
    !isNumber(numerator) ||
    !denominator ||
    isNaN(denominator) ||
    isNaN(numerator)
  ) {
    return 0;
  }

  return numerator / denominator;
};

export const randomNumberInRange = (min: number, max: number) => {
  // find diff
  const difference = max - min;

  // generate random number
  let rand = Math.random();

  // multiply with difference
  rand = Math.floor(rand * difference);

  // add with min value
  rand = rand + min;

  return rand;
};

export const clampNumber = (min: number, max: number, val: number) =>
  Math.min(Math.max(min, +val), max);
