import {
  Fieldset as MantineFieldset,
  FieldsetProps as MantineFieldsetProps
} from '@mantine/core';

/**
 * @see https://mantine.dev/core/fieldset/
 */
export const Fieldset = MantineFieldset;
export type FieldsetProps = MantineFieldsetProps;
