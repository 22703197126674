import { ElementProps, getThemeColor } from '@mantine/core';
import cx from 'clsx';
import { FCC } from 'fcc';
import { forwardRef, ReactNode } from 'react';

import { Box, BoxProps } from '@/common/components/Display/Box';
import { useTheme } from '@/theme';

import classes from './StyledFormComponents.module.css';

interface Props extends BoxProps {
  colorVariant?: string;
}

export const FormHelp: FCC<Props> = ({ children, colorVariant, ...rest }) => {
  const theme = useTheme();
  return (
    <Box
      className={classes.formHelp}
      color={getThemeColor(colorVariant, theme)}
      {...rest}
    >
      {children}
    </Box>
  );
};

interface FormErrorProps extends BoxProps, ElementProps<'div'> {
  children?: ReactNode;
}

export const FormError = forwardRef<HTMLDivElement, FormErrorProps>(
  ({ children, className, ...rest }, ref) => {
    return (
      <Box ref={ref} className={cx(className, classes.formError)} {...rest}>
        {children}
      </Box>
    );
  }
);
