import { asBoolean } from '@/common/utils/BooleanFunctions';

export class ClientRequestInfo {
  clientIp?: string;
  referer?: string;
  refererDomain?: string;
  userAgent?: string;
  clientRegion?: string;
  clientCity?: string;
  clientCityLatLong?: string;
  isBot?: boolean;

  constructor(props?: Partial<ClientRequestInfo>) {
    props = props || {};
    Object.assign(this, props);
    this.isBot = asBoolean(props.isBot);
  }

  public static fromJson(json?: string): ClientRequestInfo {
    try {
      return new ClientRequestInfo(JSON.parse(json || '{}'));
    } catch (e) {
      console.error('Error parsing ClientRequestInfo from json', e);
    }
    return new ClientRequestInfo();
  }
}
