import { useMemo } from 'react';
import { FieldValues } from 'react-hook-form';

import { ValidatorType } from '../../_shared';
import { MobileValue } from '../../FormMobileInput';
import { FormHookMobileInput } from '../../FormMobileInput/FormHookMobileInput';
import {
  DynamicFormContextData,
  IAmDynamicFormFieldProps,
  MobileDynamicFieldOptions
} from '../types';
import { DynamicFormHookField } from './__DynamicFormHookField';

export function DynamicFormMobileField<
  TFieldValues extends FieldValues = FieldValues,
  TContextData extends DynamicFormContextData = DynamicFormContextData
>({
  field,
  disabled,
  contextData,
  ...rest
}: IAmDynamicFormFieldProps<TFieldValues, TContextData>) {
  const { required } = field;

  const extraValidators = useMemo(() => {
    const validators: ValidatorType<TFieldValues, TContextData>[] = [];
    if (required) {
      validators.push((v: MobileValue) => {
        if (!v) return undefined; //Handled by the default required validator

        if (!(v?.phone || '')?.trim()) {
          return 'Field is required';
        }
        return undefined;
      });
    }

    //Hacky solution to validate Australian phone numbers
    validators.push((v: MobileValue) => {
      const value = (v?.phone || '')?.trim();
      if (!value) return undefined; //We only deal with numbers that are not empty

      if (v.dialCode === '+61') {
        const startsWith0 = value.startsWith('0');
        const startsWith4 = value.startsWith('4');
        if (!startsWith0 && !startsWith4) {
          return 'Must start with 0 or 4';
        }

        if (startsWith0 && value.length !== 10) {
          return 'Must be 10 numbers when starting with 0';
        }

        if (startsWith4 && value.length !== 9) {
          return 'Must be 9 numbers when starting with 4';
        }
      }

      if (!v.dialCode) {
        return 'Country code is required';
      }

      return undefined;
    });

    return validators;
  }, [required]);

  return (
    <DynamicFormHookField<TFieldValues, TContextData>
      field={field}
      disabled={disabled}
      contextData={contextData}
      extraValidators={extraValidators}
    >
      {(h) => (
        <FormHookMobileInput
          hook={h}
          {...((field.data.options as MobileDynamicFieldOptions) || {})}
          {...rest}
        />
      )}
    </DynamicFormHookField>
  );
}
