import { FCC } from 'fcc';

import { useTheme } from '@/theme';

import { Group } from '../Display';

export const FeedbackDisplay: FCC = ({ children }) => {
  const theme = useTheme();
  return (
    <Group
      align="center"
      c={theme.colors.gray[6]}
      fz={theme.fontSizes.xs}
      mb={0}
      gap={'0.5rem'}
    >
      {children}
    </Group>
  );
};
