import { ClientRequestInfo } from '@/common/models/ClientRequestInfo';
import { Dictionary } from '@/common/models/Dictionary';
import { Guid } from '@/common/models/Guid';

export interface GetCurrentUserSessionSsrRequest {
  userFingerprintId?: string | Guid;
  profileId?: string | Guid;
  isBot?: boolean;
}

export class SsoRequest {
  key: string;
  attributes: Record<string, string>;

  constructor(props?: Partial<SsoRequest>) {
    Object.assign(this, props || {});
  }
}

export interface MagicLinkRequest {
  token: string;
  siteId: string | Guid;
}

export interface CheckEntryConditionRequest {
  siteId: Guid;
  entryCondition?: string;
}

export class SsoResponse {
  profileId: Guid;
  userId: Guid;

  constructor(props?: Partial<SsoResponse>) {
    props = props || {};
    this.profileId = Guid.valueOrUndefined(props.profileId);
    this.userId = Guid.valueOrUndefined(props.userId);
  }
}

export interface InitUserSessionV2Request {
  siteId: Guid;
  queryParams: Dictionary<string>;
  clientRequestInfo: ClientRequestInfo;
}

export interface CollectActivityOptions {
  isIdle?: boolean;
  useBeacon?: boolean;
}
