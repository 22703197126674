import { FCC } from 'fcc';

export type PortalComponent = FCC<any>;
export const validateFunctionalComponent = (Component: PortalComponent) => {
  const prototype = Component.prototype;
  const isValid = !prototype || !prototype.isReactComponent;
  if (!isValid) {
    throw new Error('Only stateless components can be used in portals.');
  }
  return;
};
