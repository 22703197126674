import { FCC } from 'fcc';
import { FC, memo } from 'react';

import { Box } from '@/common/components/Display';
import { useFrontPage } from '@/front/components/site/SiteHost/Store';

import { useFrontSite } from '../Store/FrontSiteStore';
import classes from './SiteBackground.module.css';

export const SiteBackground: FCC = memo(({ children }) => {
  const siteProperties = useFrontSite((x) => x.site?.properties);
  const pageProperties = useFrontPage((x) => x.page?.properties);
  const properties = pageProperties ?? siteProperties;
  const {
    BackgroundImageEnabled,
    BackgroundColor,
    BackgroundImageModel,
    BackgroundFilter
  } = properties;

  return (
    <Box
      __vars={{
        '--site-background-color': BackgroundColor,
        '--site-background-image': `url(${BackgroundImageModel?.url})`
      }}
      className={classes.root}
      data-bg-image-enabled={BackgroundImageEnabled && !!BackgroundImageModel}
    >
      <SiteBackgroundFilter backgroundFilter={BackgroundFilter?.toString()} />
      {children}
    </Box>
  );
});

interface StyledBackgroundFilterProps {
  backgroundFilter?: string;
}

export const SiteBackgroundFilter: FC<StyledBackgroundFilterProps> = memo(
  ({ backgroundFilter }) => {
    if (!backgroundFilter) {
      return null;
    }

    return (
      <Box bg={backgroundFilter} className={classes.styledBackgroundFilter} />
    );
  }
);
