import { FC } from 'react';

import { IconProps } from '../Icons/IconProps';
import { IconSvg } from '../Icons/IconSvg';

export const PinterestIcon: FC<IconProps> = ({ color, ...rest }) => {
  return (
    <IconSvg {...rest}>
      <g clipPath="url(#clip0_7944_55642)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7.48429 1.09817C8.91593 0.505167 10.4504 0.199951 12 0.199951C13.5496 0.199951 15.084 0.505167 16.5156 1.09817C17.9473 1.69118 19.2481 2.56036 20.3438 3.65609C21.4395 4.75182 22.3087 6.05264 22.9017 7.48429C23.4947 8.91593 23.8 10.4504 23.8 12C23.8 13.5495 23.4947 15.084 22.9017 16.5156C22.3087 17.9473 21.4395 19.2481 20.3438 20.3438C19.2481 21.4395 17.9473 22.3087 16.5156 22.9017C15.084 23.4947 13.5495 23.8 12 23.8C10.4504 23.8 8.91593 23.4947 7.48429 22.9017C6.05264 22.3087 4.75182 21.4395 3.65609 20.3438C2.56036 19.2481 1.69118 17.9473 1.09817 16.5156C0.505167 15.084 0.199951 13.5496 0.199951 12C0.199951 10.4504 0.505167 8.91593 1.09817 7.48429C1.69118 6.05265 2.56036 4.75182 3.65609 3.65609C4.75182 2.56036 6.05264 1.69118 7.48429 1.09817ZM12 2.19995C10.713 2.19995 9.43864 2.45344 8.24965 2.94593C7.06066 3.43843 5.98032 4.16029 5.0703 5.0703C4.16029 5.98032 3.43843 7.06066 2.94593 8.24965C2.45344 9.43864 2.19995 10.713 2.19995 12C2.19995 13.2869 2.45344 14.5613 2.94593 15.7502C3.43843 16.9392 4.16029 18.0196 5.0703 18.9296C5.98032 19.8396 7.06066 20.5615 8.24965 21.054C9.43864 21.5465 10.713 21.8 12 21.8C13.2869 21.8 14.5613 21.5465 15.7502 21.054C16.9392 20.5615 18.0196 19.8396 18.9296 18.9296C19.8396 18.0196 20.5615 16.9392 21.054 15.7502C21.5465 14.5613 21.8 13.2869 21.8 12C21.8 10.713 21.5465 9.43864 21.054 8.24965C20.5615 7.06066 19.8396 5.98032 18.9296 5.0703C18.0196 4.16029 16.9392 3.43843 15.7502 2.94593C14.5613 2.45344 13.2869 2.19995 12 2.19995Z"
          fill={color || 'currentColor'}
        />
        <path
          d="M12.1679 5C9.16615 5 6.19995 7.00102 6.19995 10.2396C6.19995 12.2992 7.35844 13.4694 8.06055 13.4694C8.35029 13.4694 8.51692 12.6619 8.51692 12.434C8.51692 12.162 7.8237 11.5825 7.8237 10.4503C7.8237 8.09819 9.61409 6.43044 11.9311 6.43044C13.9232 6.43044 15.3976 7.56271 15.3976 9.64284C15.3976 11.1964 14.7746 14.1102 12.7558 14.1102C12.0275 14.1102 11.404 13.5836 11.404 12.829C11.404 11.723 12.1763 10.6525 12.1763 9.51131C12.1763 7.57488 9.4292 7.92594 9.4292 10.2663C9.4292 10.7578 9.49052 11.3017 9.71005 11.7496C9.3061 13.4876 8.48135 16.0765 8.48135 17.8669C8.48135 18.4197 8.56046 18.9641 8.61288 19.5169C8.71211 19.6283 8.6625 19.6166 8.81462 19.5609C10.2891 17.5421 10.2366 17.1475 10.9036 14.5057C11.2636 15.1905 12.1937 15.5588 12.9309 15.5588C16.0375 15.5588 17.4333 12.5309 17.4333 9.80152C17.4333 6.89664 14.923 5.00094 12.1674 5.00094L12.1679 5Z"
          fill={color || 'currentColor'}
        />
      </g>
      <defs>
        <clipPath id="clip0_7944_55642">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </IconSvg>
  );
};
