import { FC } from 'react';

import { Image } from '@/common/components/Image';
import { ImageDataModel } from '@/common/models/ImageDataModel';

interface Props {
  image: ImageDataModel;
  height?: number;
}
export const SiteHeaderImage: FC<Props> = ({ image, height }) => {
  return (
    <Image
      isPriority
      image={image}
      style={{
        flex: '0 0',
        height: height ?? '50px',
        width: 'auto'
      }}
    />
  );
};
