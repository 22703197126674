import { FCC } from 'fcc';

import { Anchor } from '@/common/components/Anchor';
import { Guid } from '@/common/models/Guid';
import { SiteMenuItem } from '@/common/models/site-menu/SiteMenuItem';
import { useFrontPage } from '@/front/components/site/SiteHost/Store';

import classes from './MenuAnchor.module.css';

interface Props {
  menuItem: SiteMenuItem;
}

export const MenuAnchor: FCC<Props> = ({ menuItem, children }) => {
  const page = useFrontPage((x) => x.page);

  return (
    <Anchor
      className={classes.root}
      data-active={menuItem.pageId && Guid.equals(page.id, menuItem.pageId)}
      href={menuItem.url}
      target={menuItem.properties.OpenInNewTab ? '_blank' : undefined}
      size="lg"
    >
      {children}
    </Anchor>
  );
};
