import { Guid } from '@/common/models/Guid';

export class LiveSurveyQuestionReactionUpdatedEvent {
  cardId: Guid;
  questionId: Guid;
  reactionKey: string;
  count: number = 0;

  constructor(props: Partial<LiveSurveyQuestionReactionUpdatedEvent>) {
    props = props || {};
    Object.assign(this, props);
    this.cardId = Guid.valueOrNew(props.cardId);
    this.questionId = Guid.valueOrNew(props.questionId);
  }

  canHandle(cardId: Guid | string) {
    return this.cardId.equals(cardId);
  }
}
