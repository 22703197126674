import { forwardRef } from 'react';

import { IconProps } from './IconProps';
import { getDefaultColor, IconSvg } from './IconSvg';

export const CorrectIconFilled = forwardRef<HTMLSpanElement, IconProps>(
  ({ color, ...rest }, ref) => {
    return (
      <IconSvg ref={ref} {...rest}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24ZM5.20227 13.3673L9.14609 17.3111C9.52951 17.6945 10.1512 17.6945 10.5346 17.3111L18.7989 9.04676C19.2199 8.62581 19.2211 7.9437 18.8016 7.52127C18.3801 7.09672 17.6938 7.09551 17.2707 7.51856L9.84034 14.949L6.72507 11.8424C6.3041 11.4226 5.62266 11.423 5.20227 11.8434C4.78147 12.2642 4.78147 12.9465 5.20227 13.3673Z"
          fill={color || getDefaultColor()}
        />
      </IconSvg>
    );
  }
);
