import { getSize, MantineSize, rem } from '@mantine/core';

const sizes = {
  xs: rem(8),
  sm: rem(14),
  md: rem(16),
  lg: rem(24),
  xl: rem(32)
};

export const getIconSize = (size: MantineSize | string | number) => {
  if (size in sizes) {
    return sizes[size as any];
  }

  if (size === 'inherit') {
    return size;
  }

  return getSize(size);
};
