import cx from 'clsx';
import React, { FC, forwardRef, ReactNode } from 'react';

import { Box } from '@/common/components/Display/Box';
import { KomoLogoIcon } from '@/common/components/Icons/KomoLogoIcon';
import { Paper, PaperProps } from '@/common/components/Paper';
import { Title } from '@/common/components/Typography/Title';

import classes from './AuthPanel.module.css';

interface Props {
  title?: string;
  children: ReactNode;
  postContent?: ReactNode;
}

export const AuthPanel: FC<Props> = ({ title, children, postContent }) => {
  return (
    <div className={classes.root}>
      <AuthPanelWrapper>
        <Box ta="center" mb="1rem">
          <KomoLogoIcon className={classes.icon} />
          {title && (
            <Title order={4} mt="1rem" fw={400}>
              {title}
            </Title>
          )}
        </Box>
        {children}
      </AuthPanelWrapper>
      {postContent}
    </div>
  );
};

interface AuthPanelWrapperProps extends Omit<PaperProps, 'display'> {
  children: ReactNode;
}

export const AuthPanelWrapper = forwardRef<
  HTMLDivElement,
  AuthPanelWrapperProps
>(
  (
    {
      p = '2rem',
      w = '100%',
      radius = 'sm',
      shadow = 'xs',
      className,
      children,
      ...rest
    },
    ref
  ) => {
    return (
      <Paper
        ref={ref}
        w={w}
        p={p}
        shadow={shadow}
        radius={radius}
        className={cx(classes.wrapper, className)}
        {...rest}
      >
        {children}
      </Paper>
    );
  }
);
