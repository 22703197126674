import { forwardRef } from 'react';

import { FormTextInputProps } from '@/common/components/Form/FormTextInput';

import {
  FormCountrySelect,
  FormCountrySelectProps
} from '../../../FormSelect/Country/FormCountrySelectV2';
import { resolveFieldName } from './DynamicFieldAddress.types';

interface Props
  extends Pick<
    FormCountrySelectProps,
    'error' | 'disabled' | 'size' | 'required' | 'allowedCountries'
  > {
  fieldName: string;
  onChange: (value: string) => void;
  value: FormTextInputProps['value'];
}

export const CountryField = forwardRef<HTMLInputElement, Props>(
  ({ onChange, fieldName, value, allowedCountries, ...rest }, ref) => {
    return (
      <FormCountrySelect
        ref={ref}
        name={resolveFieldName(fieldName, 'country')}
        dataVariant="default"
        label={'Country/Region'}
        allowedCountries={allowedCountries}
        value={value}
        onChange={onChange}
        {...rest}
      />
    );
  }
);
