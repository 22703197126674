import { Dictionary, mapDictionary } from '@/common/models/Dictionary';
import { Guid } from '@/common/models/Guid';
import { utcDateOrNow } from '@/common/utils/DateFunctions';

import { SiteMenuProperties } from './shared/SiteMenuProperties';
import { SiteMenuItem } from './SiteMenuItem';

export class SiteMenu {
  id: Guid;
  siteId: Guid;
  name: string;
  items: Dictionary<SiteMenuItem>;
  updatedAt: Date;
  properties: SiteMenuProperties;

  constructor(props?: Partial<SiteMenu>) {
    props = props || {};
    Object.assign(this, props);
    this.id = Guid.valueOrNew(props.id);
    this.siteId = Guid.valueOrUndefined(props.siteId);
    this.items = mapDictionary(props.items, (x) => new SiteMenuItem(x));
    this.updatedAt = utcDateOrNow(props.updatedAt);
    this.properties = new SiteMenuProperties(props.properties);
  }
}
