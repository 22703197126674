import { FCC } from 'fcc';
import { memo } from 'react';

import { Center, CenterProps } from '@/common/components/Display/Center';
import { KomoIcon } from '@/common/components/Icons/KomoIcon';

import classNames from './KomoLoader.module.css';

export interface KomoLoaderProps extends Omit<CenterProps, 'children'> {}

export const KomoLoader: FCC<KomoLoaderProps> = memo(
  ({ children, ...rest }) => (
    <Center h="100%" w="100%" {...rest}>
      <span className={classNames.LogoWrapper}>
        <KomoIcon size={'xl'} />
      </span>
      {children}
    </Center>
  )
);
