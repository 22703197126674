import { NextRouter } from 'next/router';

import { EmbeddedFunctions } from '../../common/utils/EmbeddedFunctions';
import { openNewWindow } from './OpenNewWindow';
import { SiteCache } from './SiteCache';

export const oAuthSignIn = (router: NextRouter) => {
  const isInIframe = EmbeddedFunctions.isInIframe();
  const redirectUrl = `/api/v1/live/auth/oauth/authorize?returnTo=${encodeURIComponent(
    window.location.href
  )}&userId=${SiteCache.getState().userId}`;
  if (isInIframe) {
    openNewWindow(redirectUrl, 'signInPopup', () => {
      router.reload();
    });
  } else {
    window.location.href = redirectUrl;
  }
};
