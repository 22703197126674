import get from 'lodash/get';
import {
  DeepMap,
  FieldError,
  FieldErrors,
  UseFormReturn
} from 'react-hook-form';

import { KeyOf } from '@/common/models/KeyOf';

function getError<TFieldValues extends object>(
  errors: FieldErrors<TFieldValues>,
  name: KeyOf<TFieldValues>,
  overrideRequiredError?: string,
  isFieldArray?: boolean
) {
  const error = isFieldArray
    ? (getFieldArrayError(errors, name) as FieldError)
    : (get(errors, name) as FieldError);
  if (!error) return undefined;
  if (error.message) return error.message;
  if (error.type === 'required')
    return overrideRequiredError ?? 'This field is required';
  return 'invalid field';
}

function getFieldArrayError<TFieldValues extends object>(
  errors: FieldErrors<TFieldValues>,
  name: KeyOf<TFieldValues>
) {
  const [arrayName, index, fieldName] = name.toString().split('.');
  const arrayErrors = errors[arrayName];
  if (!arrayErrors) return undefined;
  if (!arrayErrors[index]) {
    return undefined;
  }
  return arrayErrors[index][fieldName];
}

interface OnSubmitOptions<TFieldValues> {
  onError?: (error: DeepMap<TFieldValues, any>) => void;
  noFocusOnError?: boolean;
}
function onSubmit<TFieldValues = any>(
  methods: Pick<UseFormReturn<TFieldValues>, 'handleSubmit'>,
  onSuccess: (values: TFieldValues) => void,
  options?: OnSubmitOptions<TFieldValues>
) {
  return methods.handleSubmit(
    (values) => {
      onSuccess(values as any);
    },
    (e) => {
      options?.onError?.(e as any);
      if (!Object.keys(e).length) return;

      if (!options?.noFocusOnError) {
        //Currently have an issue where react hook form isn't focussing on error.
        //Wrapping in a timeout works for now. Hopefully they fix it and we can avoid this
        const errorsThatCanFocus = Object.values(e).filter(
          (x: any) => x.ref && x.ref.focus
        );
        if (!errorsThatCanFocus.length) return;
        setTimeout(() => {
          (errorsThatCanFocus[0] as any).ref.focus();
        }, 50);
      }
    }
  );
}

export const FormHookHelpers = {
  getError: getError,
  onSubmit: onSubmit
};
