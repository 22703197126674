import { UtmParameters } from '../models/UtmParameters';
import { isLocalhost, isLocalHostname } from './NextFunctions';
import { QueryParams } from './QueryParams';

export const isValidHostName = (domain: string) =>
  /^((?!-))(xn--)?[a-z\d][a-z\d-_]{0,61}[a-z\d]*\.?((xn--)?([a-z\d\-.]{1,61}|[a-z\d-]{0,30})\.[a-z-1-9]{2,})$/i.test(
    domain
  );

interface ValidateUrlData {
  noProtocol: boolean;
  isBlockedProtocol: boolean;
  isEmpty: boolean;
  isHttp: boolean;
  isHttps: boolean;
  isValidHostName: boolean;
}
export const isValidUrl = (
  url: string,
  requireHttps?: boolean
): [boolean, ValidateUrlData] => {
  const data: ValidateUrlData = {
    isEmpty: true,
    noProtocol: true,
    isBlockedProtocol: false,
    isHttp: false,
    isHttps: false,
    isValidHostName: false
  };
  try {
    if (!url) return [false, data];
    data.isEmpty = false;
    const parsed = new URL(url);
    data.isHttp = parsed.protocol === 'http:';
    data.isHttps = parsed.protocol === 'https:';
    data.isBlockedProtocol = blockedProtocols.includes(parsed.protocol);
    data.noProtocol = !parsed.protocol;
    data.isValidHostName =
      (isLocalhost() && isLocalHostname(parsed.hostname)) ||
      isValidHostName(parsed.hostname);
    let success =
      (!(data.isHttp || data.isHttps) || data.isValidHostName) &&
      !data.noProtocol &&
      !data.isBlockedProtocol;
    if (requireHttps && success) {
      success = data.isHttps;
    }

    return [success, data];
  } catch {
    return [false, data];
  }
};

const blockedProtocols = ['javascript:', 'data:', 'file:', 'ftp:'];

// A slug can only have alphanumeric characters, underscores and hyphens
export const isValidUrlSlug = (slug: string): boolean => {
  return /^[a-zA-Z0-9_-]+$/.test(slug);
};

export const isUrlSlugReserved = (
  slug: string,
  reservedKeywords: string[],
  checkHttpStatusCodes: boolean = true
): boolean => {
  if (reservedKeywords.includes(slug.toLowerCase())) {
    return true;
  }

  if (checkHttpStatusCodes) {
    return /^[1-5][0-9]{2}$/.test(slug);
  }

  return false;
};

export const buildUrl = (url: string, params: Record<string, string>) =>
  `${url}${new QueryParams(params).toString()}`;

export const trimTrailingSlash = (url: string): string => {
  if (!url || url.length === 1) {
    return url;
  }
  return url[url.length - 1] === '/' ? url.substring(0, url.length - 1) : url;
};

type GetParamsFromUrlResponse = [string, Record<string, string>];

export const getParamsFromUrl = (fullUrl: string): GetParamsFromUrlResponse => {
  const fallback: GetParamsFromUrlResponse = [fullUrl, {}];
  if (!fullUrl) return fallback;
  try {
    const urlQuerySplit = fullUrl.split('?');
    if (urlQuerySplit.length === 1) return fallback;
    const url = urlQuerySplit[0];
    const fullQuery = urlQuerySplit[1];
    const rawQueries = fullQuery.split('&');
    const records: Record<string, string> = {};
    rawQueries.forEach((q) => {
      const keyValue = q.split('=');
      records[keyValue[0]] = decodeURIComponentWithSanitizing(keyValue[1]);
    });

    return [url, records];
  } catch (e) {
    console.error(e);
    return fallback;
  }
};

export interface UrlQueryParamOption {
  key: string;
  value: string;
}

// https://chromium.googlesource.com/chromium/src.git/+/62.0.3178.1/third_party/google_input_tools/third_party/closure_library/closure/goog/string/string.js?autodive=0%2F%2F%2F%2F#486
//
//  URL-decodes the string. We need to specially handle '+'s because
//  the javascript library doesn't convert them to spaces.
//  @param {string} str The string to url decode.
//  @return {string} The decoded {@code str}.
//
export const decodeURIComponentWithSanitizing = (str: string) => {
  if (!str) return str;
  return decodeURIComponent(str.replace(/\+/g, ' '));
};

// https://github.com/facebook/lexical/blob/59504dc5b203055dce5917ff6a53dc427636e1c4/packages/lexical-playground/src/utils/url.ts
export const sanitizeUrl = (url: string) => {
  /** A pattern that matches safe  URLs. */
  const SAFE_URL_PATTERN =
    /^(?:(?:https?|mailto|ftp|tel|file|sms):|[^&:/?#]*(?:[/?#]|$))/gi;

  /** A pattern that matches safe data URLs. */
  const DATA_URL_PATTERN =
    /^data:(?:image\/(?:bmp|gif|jpeg|jpg|png|tiff|webp)|video\/(?:mpeg|mp4|ogg|webm)|audio\/(?:mp3|oga|ogg|opus));base64,[a-z0-9+/]+=*$/i;

  url = String(url).trim();

  if (url.match(SAFE_URL_PATTERN) || url.match(DATA_URL_PATTERN)) return url;

  return 'https://';
};

export const buildUrlWithUtmParams = (
  baseUrl: string,
  utmParameters?: UtmParameters,
  data?: Record<string, any>
) => {
  if (!baseUrl) return '';
  const params = new QueryParams(data);
  utmParameters?.populateQueryParams(params);
  return `${baseUrl}${params.toString()}`;
};
