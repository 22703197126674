import { useMemo } from 'react';
import { FieldValues } from 'react-hook-form';
import isNil from 'lodash/isNil';

import { IAmFormHookField } from '../../_FormHookField';
import { FormNativeSelect, FormNativeSelectProps } from './FormNativeSelect';

export interface FormHookNativeSelectProps<
  TFieldValues extends FieldValues = FieldValues
> extends IAmFormHookField<TFieldValues>,
    Omit<
      FormNativeSelectProps,
      'required' | 'name' | 'value' | 'defaultValue' | 'error' | 'disabled'
    > {
  includeEmptyOption?: boolean;
}

export function FormHookNativeSelect<TFieldValues = any>(
  props: FormHookNativeSelectProps<TFieldValues>
) {
  const { hook, onBlur, onChange, data, includeEmptyOption, ...rest } = props;

  const options = useMemo(() => {
    if (!includeEmptyOption) {
      return data;
    }
    data.unshift({ label: '', value: '' });
    return data;
  }, [data, includeEmptyOption]);

  return (
    <FormNativeSelect
      {...rest}
      required={hook.required}
      name={hook.field.name}
      ref={hook.field.ref}
      disabled={hook.disabled}
      onBlur={(e) => {
        hook.field.onBlur();
        onBlur?.(e);
      }}
      onChange={(e) => {
        hook.field.onChange(e);
        onChange?.(e);
      }}
      value={(!isNil(hook.field.value) ? hook.field.value : '') as any}
      error={hook.showError ? hook.errorMessage : undefined}
      data={options}
    />
  );
}
