import { FC, useState } from 'react';

import { Tabs } from '@/common/components/Tabs';
import { Text } from '@/common/components/Typography/Text';
import { FrontBadgeView } from '@/front/models/FrontBadgeView';

import { useFrontSite } from '../../../Store';
import { BadgeAlbum } from './_BadgeAlbum';
import { ContactProfileHeaderButton } from './_ContactProfileHeaderButton';
import { BadgeGrid } from './BadgeGrid';

interface Props {
  onEditProfile: () => void;
  badges: FrontBadgeView[];
}

export const ProfileContent: FC<Props> = ({ onEditProfile, badges }) => {
  const properties = useFrontSite((x) => x.site.properties);
  const [activeTab, setActiveTab] = useState<string | null>(
    properties.ProfileAlbumEnabled ? 'badge-album' : 'all'
  );
  const drawerData = properties.getProfileDrawerModel();
  const badgesEnabled = properties.BadgesEnabled;
  const earnedBadges = badges.filter((x) => x.isEarned().earned);
  const tabAccentColor = drawerData.tabAccentColor || '#348cda';

  return (
    <>
      <ContactProfileHeaderButton
        enableEdit={drawerData.enableEdit}
        onClick={onEditProfile}
      />
      <Text fz={'md'} fw={400} style={{ whiteSpace: 'pre-wrap' }}>
        {drawerData.description || drawerData._DefaultDescription}
      </Text>

      {badgesEnabled && (
        <Tabs
          styledProps={{
            activeBorderColor: tabAccentColor,
            color: 'currentColor',
            activeColor: tabAccentColor,
            borderColor: `color-mix(in srgb, transparent 80%, currentColor)`
          }}
          value={activeTab}
          onChange={setActiveTab}
        >
          <Tabs.List grow style={{ borderBottom: 'none' }}>
            {properties.ProfileAlbumEnabled && (
              <Tabs.Tab value="badge-album">
                <Text fz={'md'}>
                  {drawerData.albumTabName || drawerData._DefaultAlbumTabName}
                </Text>
              </Tabs.Tab>
            )}
            <Tabs.Tab value="all" rightSection={`(${badges.length})`}>
              <Text fz={'md'}>All</Text>
            </Tabs.Tab>
            <Tabs.Tab value="earned" rightSection={`(${earnedBadges.length})`}>
              <Text fz={'md'}>Earned</Text>
            </Tabs.Tab>
          </Tabs.List>

          <Tabs.Panel value="all">
            <BadgeGrid data={badges} />
          </Tabs.Panel>
          <Tabs.Panel value="earned">
            <BadgeGrid data={earnedBadges} />
          </Tabs.Panel>
          <Tabs.Panel value="badge-album">
            <BadgeAlbum />
          </Tabs.Panel>
        </Tabs>
      )}
    </>
  );
};
