import { Group } from '@/common/components/Display';
import { SiteMenu } from '@/common/models/site-menu/SiteMenu';

import { MenuAnchor } from '../MenuAnchor/MenuAnchor';
import classes from './MenuGroup.module.css';

interface Props {
  menu: SiteMenu;
}

export const MenuGroup = ({ menu }: Props) => {
  const menuItems = menu.properties.ItemOrderArray.map((id) => {
    if (!menu.items[id.toString()]) {
      return <></>;
    }

    const item = menu.items[id.toString()];
    return (
      <MenuAnchor key={id.toString()} menuItem={item}>
        {item.name}
      </MenuAnchor>
    );
  });

  return <Group className={classes.root}>{menuItems}</Group>;
};
