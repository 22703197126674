import { useMutation } from '@tanstack/react-query';
import { useRouter } from 'next/router';
import { useState } from 'react';

import { useOnMount } from '@/common/hooks/useOnMount';
import { ClientRequestInfo } from '@/common/models/ClientRequestInfo';
import { Dictionary } from '@/common/models/Dictionary';
import { Guid } from '@/common/models/Guid';
import { Site } from '@/common/models/site';
import { SiteUser } from '@/common/models/SiteUser';
import { DataDogServiceHelper } from '@/common/utils/DataDogService';
import { useFrontPage } from '@/front/components/site/SiteHost/Store';
import { useInitialQueryParams } from '@/front/components/site/SiteHost/useInitialQueryParams';
import { PublicContactsService } from '@/front/data/Contacts';
import { PublicSiteService } from '@/front/data/Sites';
import { useSiteCache } from '@/front/utils/SiteCache';

interface TrackViewedProps {
  clientRequestInfo: ClientRequestInfo;
  initialQueryParams: Dictionary<string>;
}

interface InitSiteUserProps {
  site: Site;
  siteUser?: SiteUser;
  clientRequestInfo: ClientRequestInfo;
  onTrackViewed?: (props: TrackViewedProps) => void;
}

const minDelay = 500;
const maxDelay = 10 * 1000;
const jitteredExponentialBackoff = (attempt: number) =>
  minDelay +
  Math.round(Math.random() * Math.min(2 ** attempt * 1000, maxDelay));

export const useInitSiteUser = ({
  site,
  siteUser: siteUserProp,
  clientRequestInfo,
  onTrackViewed
}: InitSiteUserProps) => {
  const page = useFrontPage((x) => x.page);
  const [isSiteUserLoading, setIsSiteUserLoading] = useState(true);
  const initCache = useSiteCache((x) => x.init);
  const [siteUser, setSiteUser] = useState<SiteUser>(siteUserProp);
  const { query } = useRouter();
  const initialQueryParams = useInitialQueryParams({ query });

  const { mutateAsync: createContactAsync } = useMutation({
    mutationFn: PublicContactsService.actions.createAsync,
    retry: 3,
    retryDelay: jitteredExponentialBackoff,
    onError: (e) => console.error(e)
  });

  const { mutateAsync: currentSessionAsync } = useMutation({
    mutationFn: PublicSiteService.actions.currentSessionAsync,
    retry: 3,
    retryDelay: jitteredExponentialBackoff,
    onError: (e) => console.error(e)
  });

  const { mutateAsync: initSessionV2Async } = useMutation({
    mutationFn: PublicSiteService.actions.initSessionV2Async,
    retry: 3,
    retryDelay: jitteredExponentialBackoff,
    onError: (e) => console.error(e)
  });

  useOnMount(async () => {
    try {
      if (siteUser) {
        initCache(site.id, siteUser.userId, page?.id, page?.slug);
        DataDogServiceHelper.trySetUserInformation(siteUser);
        if (clientRequestInfo.isBot) return;

        let hasCurrentContact = siteUser.hasContact;
        if (!hasCurrentContact) {
          const contact = await createContactAsync();
          if (Guid.isValid(contact.id)) {
            hasCurrentContact = true;
            setSiteUser(new SiteUser({ ...siteUser, hasContact: true }));
          }
        }

        if (hasCurrentContact) {
          try {
            await initSessionV2Async({
              siteId: site.id,
              queryParams: initialQueryParams,
              clientRequestInfo
            });
          } catch (e) {
            console.error('unable to init session v2', e);
          }
        }

        onTrackViewed?.({ initialQueryParams, clientRequestInfo });
        return;
      }

      const user = await currentSessionAsync({
        siteId: site.id,
        isBot: clientRequestInfo.isBot
      });

      initCache(site.id, user.userId, page?.id, page?.slug);
      DataDogServiceHelper.trySetUserInformation(user);

      if (clientRequestInfo.isBot) {
        setSiteUser(user);
        return;
      }

      let hasContact = user.hasContact;
      if (!hasContact) {
        const contact = await createContactAsync();
        hasContact = Guid.isValid(contact.id);
      }

      if (hasContact) {
        try {
          await initSessionV2Async({
            siteId: site.id,
            queryParams: initialQueryParams,
            clientRequestInfo
          });
        } catch (e) {
          console.error('unable to init session v2', e);
        }
      }

      setSiteUser(new SiteUser({ ...user, hasContact }));

      onTrackViewed?.({ initialQueryParams, clientRequestInfo });
    } catch (e) {
      console.error(e);
    } finally {
      setIsSiteUserLoading(false);
    }
  });

  return [siteUser, isSiteUserLoading] as const;
};
