import { FCC } from 'fcc';

import { Box, BoxProps } from '../Display';
import classes from './ListItemIcon.module.css';

// eslint-disable-next-line @typescript-eslint/no-empty-interface

interface Props extends BoxProps {}

export const ListItemIcon: FCC<Props> = ({ children, ...rest }) => {
  return (
    <Box className={classes.root} {...rest}>
      {children}
    </Box>
  );
};
ListItemIcon.displayName = 'ListItemIcon';
