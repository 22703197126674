import { ClientRequestInfo } from '@/common/models/ClientRequestInfo';
import { Dictionary } from '@/common/models/Dictionary';
import { Guid } from '@/common/models/Guid';
import { Site } from '@/common/models/site/Site';
import { ShareNetworks } from '@/common/utils/ShareNetworks';
import { FrontIntegrationEventTypes } from '@/front/data/IntegrationService/FrontIntegrationEventTypes';
import { IntegrationService } from '@/front/data/IntegrationService/IntegrationService';
import { PublicLiveApi } from '@/front/data/PublicApi';

import { SiteCache } from '../utils/SiteCache';

export interface BaseCardEventApiRequest {
  cardId: Guid;
  clientRequestInfo?: ClientRequestInfo;
}

export interface CardShareEventApiRequest extends BaseCardEventApiRequest {
  shareType: ShareNetworks;
  shareId: string;
  description?: string;
  embeddedHostUrl?: string;
}

export interface CardShareClickedApiRequest extends BaseCardEventApiRequest {
  shareType: ShareNetworks;
  shareId: string;
  userId: Guid;
  embeddedHostUrl?: string;
  botName?: string;
}

export enum AuthEventViewTypes {
  Unknown = 'Unknown',
  ForgotPasswordForm = 'ForgotPasswordForm',
  SignInForm = 'SignInForm',
  SignUpForm = 'SignUpForm'
}

export interface AuthViewEventApiRequest {
  type: AuthEventViewTypes;
  source: 'Site' | 'Competition' | 'LiveTriviaGame';
  isContactVerification?: boolean;
}

export enum VideoInteractions {
  Played = 'Played',
  Paused = 'Paused',
  Resumed = 'Resumed',
  Finished = 'Finished',
  Viewed = 'Viewed'
}

export type CardActions =
  | 'Competition'
  | 'ExternalLink'
  | 'ScrollToCard'
  | 'OpenProfileDrawer'
  | 'SeenInstructions';

export interface CardActionEventApiRequest extends BaseCardEventApiRequest {
  action: CardActions;
  linkTo?: string;
}

export interface CarouselItemViewEventApiRequest
  extends BaseCardEventApiRequest {
  itemIndex: number;
  itemId: string;
}

export interface VideoInteractionEventApiRequest
  extends BaseCardEventApiRequest {
  type: VideoInteractions;
}

export interface TriviaGameSocialClickEventApiRequest {
  triviaGameplayId: Guid;
  medium: string;
  link: string;
}

export interface TriviaGameActionBarClickEventApiRequest {
  triviaGameplayId: Guid;
  link: string;
}

export interface TrackPageViewApiRequest {
  queryParams: Dictionary<string>;
  clientRequestInfo: ClientRequestInfo;
}

export interface TrackCardPageViewApiRequest extends BaseCardEventApiRequest {
  queryParams: Dictionary<string>;
}

export class PublicEventService {
  public static async trackPageViewedAsync(
    site: Site,
    queryParams: Dictionary<string>,
    clientRequestInfo: ClientRequestInfo
  ) {
    const cacheState = SiteCache.getState();
    const request: TrackPageViewApiRequest = {
      queryParams: queryParams,
      clientRequestInfo: clientRequestInfo
    };

    try {
      await PublicLiveApi.post(`sites/${cacheState.siteId}/events/pv`, request);

      await IntegrationService.staticTrackEventAsync(
        `page-viewed`,
        {
          siteId: cacheState.siteId,
          userId: cacheState.userId,
          pageId: cacheState.pageId,
          pageSlug: cacheState.pageSlug,
          ...request
        },
        site
      );
    } catch (e) {
      console.error('Error tracking page viewed', e);
      //Ignore
    }
  }

  public static async trackAuthViewedAsync(
    site: Site,
    request: AuthViewEventApiRequest
  ) {
    try {
      await PublicLiveApi.post(`sites/${site.id}/events/auth-view`, request);
      await IntegrationService.staticTrackEventAsync(
        `auth-view`,
        {
          siteId: site.id,
          userId: SiteCache.getState().userId,
          ...request
        },
        site
      );
    } catch (e) {
      console.error('Error tracking site auth viewed', e);
      //Ignore
    }
  }

  public static async trackCardSharedAsync(
    site: Site,
    { cardId, ...rest }: CardShareEventApiRequest,
    trackingData: undefined | any = {}
  ) {
    try {
      const cacheState = SiteCache.getState();
      await PublicLiveApi.post(`cards/${cardId}/events/shared`, rest);
      await IntegrationService.staticTrackEventAsync(
        `card-shared`,
        {
          cardId,
          ...rest,
          userId: cacheState.userId,
          pageId: cacheState.pageId,
          pageSlug: cacheState.pageSlug,
          ...trackingData
        },
        site
      );
    } catch (e) {
      console.error('Error tracking card shared', e);
      //Ignore
    }
  }

  public static async trackCardShareClickedAsync(
    siteId: Guid,
    request: CardShareClickedApiRequest
  ) {
    try {
      await PublicLiveApi.post(
        `site-events/${siteId}/card-share-clicked`,
        request
      );
    } catch (e) {
      console.error('Error tracking card share clicked', e);
      //Ignore
    }
  }

  public static async trackCardViewedAsync(
    site: Site,
    request: BaseCardEventApiRequest,
    trackingData: undefined | any = {}
  ) {
    try {
      const cacheState = SiteCache.getState();
      await PublicLiveApi.post(
        `cards/${request.cardId}/events/viewed`,
        request
      );

      await IntegrationService.staticTrackEventAsync(
        `card-viewed`,
        {
          siteId: site.id,
          userId: cacheState.userId,
          pageId: cacheState.pageId,
          pageSlug: cacheState.pageSlug,
          ...request,
          ...trackingData
        },
        site
      );
    } catch (e) {
      console.error('Error tracking card viewed', e);
      //Ignore
    }
  }

  public static async trackSingleCardSiteViewedAsync(
    site: Site,
    request: TrackCardPageViewApiRequest
  ) {
    try {
      await PublicLiveApi.post(`cards/${request.cardId}/events/pv`, request);

      await IntegrationService.staticTrackEventAsync(
        `single-card-site-viewed`,
        {
          siteId: site.id,
          userId: SiteCache.getState().userId,
          ...request
        },
        site
      );
    } catch (e) {
      console.error('Error tracking card page viewed', e);
      //Ignore
    }
  }

  public static async trackCarouselItemViewAsync(
    site: Site,
    { cardId, ...rest }: CarouselItemViewEventApiRequest,
    trackingData: undefined | any = {}
  ) {
    try {
      const cacheState = SiteCache.getState();
      await PublicLiveApi.post(`carousels/${cardId}/events/item-viewed`, rest);

      await IntegrationService.staticTrackEventAsync(
        `card-clicked`,
        {
          siteId: site.id,
          cardId,
          userId: cacheState.userId,
          pageId: cacheState.pageId,
          pageSlug: cacheState.pageSlug,
          ...rest,
          ...trackingData
        },
        site
      );
    } catch (e) {
      console.error('Error tracking carousel item viewed', e);
      //Ignore
    }
  }

  public static async trackCardActionAsync(
    site: Site,
    { cardId, ...rest }: CardActionEventApiRequest,
    trackingData: undefined | any = {}
  ) {
    try {
      const cacheState = SiteCache.getState();
      await PublicLiveApi.post(`cards/${cardId}/events/action`, rest);

      await IntegrationService.staticTrackEventAsync(
        `card-action`,
        {
          siteId: site.id,
          cardId,
          userId: cacheState.userId,
          pageId: cacheState.pageId,
          pageSlug: cacheState.pageSlug,
          ...rest,
          ...trackingData
        },
        site
      );
    } catch (e) {
      console.error('Error tracking card action', e);
      //Ignore
    }
  }

  public static async trackCoverClickedAsync(
    site: Site,
    { cardId, ...rest }: BaseCardEventApiRequest,
    trackingData: undefined | any = {}
  ) {
    try {
      const cacheState = SiteCache.getState();
      await PublicLiveApi.post(`cards/${cardId}/events/cover-clicked`, rest);

      await IntegrationService.staticTrackEventAsync(
        `cover-clicked`,
        {
          siteId: site.id,
          cardId,
          userId: cacheState.userId,
          pageId: cacheState.pageId,
          pageSlug: cacheState.pageSlug,
          ...rest,
          ...trackingData
        },
        site
      );
    } catch (e) {
      console.error('Error tracking card cover click', e);
      //Ignore
    }
  }

  public static async trackNumberGuessStarted(
    site: Site,
    { cardId, ...rest }: BaseCardEventApiRequest,
    trackingData: undefined | any = {}
  ) {
    try {
      const cacheState = SiteCache.getState();
      await PublicLiveApi.post(`number-guesses/${cardId}/events/started`, rest);

      await IntegrationService.staticTrackEventAsync(
        `card-started`,
        {
          siteId: site.id,
          cardId,
          userId: cacheState.userId,
          pageId: cacheState.pageId,
          pageSlug: cacheState.pageSlug,
          ...rest,
          ...trackingData
        },
        site
      );
    } catch (e) {
      console.error('Error tracking number guess started', e);
      //Ignore
    }
  }

  public static async tryTrackChecklistStartedAsync(
    site: Site,
    { cardId, ...rest }: BaseCardEventApiRequest,
    isNewGame: boolean,
    trackingData: undefined | any = {}
  ) {
    if (!isNewGame && SiteCache.getState().hasChecklistStarted(cardId)) {
      return;
    }

    try {
      const cacheState = SiteCache.getState();
      await PublicLiveApi.post(`checklists/${cardId}/events/started`, rest);

      SiteCache.getState().setChecklistStarted(cardId);

      await IntegrationService.staticTrackEventAsync(
        `card-started`,
        {
          siteId: site.id,
          cardId,
          userId: cacheState.userId,
          pageId: cacheState.pageId,
          pageSlug: cacheState.pageSlug,
          ...rest,
          ...trackingData
        },
        site
      );
    } catch (e) {
      console.error('Error tracking checklist started', e);
      //Ignore
    }
  }

  public static async trackVideoInteractionAsync(
    site: Site,
    { cardId, type, ...rest }: VideoInteractionEventApiRequest,
    trackingData: undefined | any = {}
  ) {
    try {
      const cacheState = SiteCache.getState();
      await PublicLiveApi.post(`videos/${cardId}/events/interaction`, {
        type,
        ...rest
      });

      await IntegrationService.staticTrackEventAsync(
        `card-${type}`.toLowerCase() as FrontIntegrationEventTypes,
        {
          siteId: site.id,
          cardId,
          userId: cacheState.userId,
          pageId: cacheState.pageId,
          pageSlug: cacheState.pageSlug,
          ...rest,
          ...trackingData
        },
        site
      );
    } catch (e) {
      console.error('Error tracking number video interaction', e);
      //Ignore
    }
  }

  public static async trackTriviaGameViewPrizesAsync(
    siteId: Guid,
    gameplayId: Guid,
    trackingData: undefined | any = {},
    site: Site
  ) {
    try {
      const cacheState = SiteCache.getState();
      await PublicLiveApi.post(`site-events/${siteId}/trivia-prize-view`, {
        triviaGameplayId: gameplayId
      });
      await IntegrationService.staticTrackEventAsync(
        `trivia-view-prizes`,
        {
          siteId: siteId,
          gameplayId,
          userId: cacheState.userId,
          pageId: cacheState.pageId,
          pageSlug: cacheState.pageSlug,
          ...trackingData
        },
        site
      );
    } catch (e) {
      console.error('Error tracking trivia prize view', e);
      //Ignore
    }
  }

  public static async trackTriviaGameSocialClickAsync(
    siteId: Guid,
    request: TriviaGameSocialClickEventApiRequest,
    trackingData: undefined | any = {},
    site: Site
  ) {
    try {
      const cacheState = SiteCache.getState();
      await PublicLiveApi.post(
        `site-events/${siteId}/trivia-game-social-click`,
        request
      );

      await IntegrationService.staticTrackEventAsync(
        'card-social-click',
        {
          siteId: siteId,
          pageId: cacheState.pageId,
          pageSlug: cacheState.pageSlug,
          ...request,
          ...trackingData
        },
        site
      );
    } catch (e) {
      console.error('Error tracking trivia social click', e);
      //Ignore
    }
  }

  public static async trackTriviaActionBarClickAsync(
    siteId: Guid,
    request: TriviaGameActionBarClickEventApiRequest,
    trackingData: undefined | any = {},
    site: Site
  ) {
    try {
      const cacheState = SiteCache.getState();
      await PublicLiveApi.post(
        `site-events/${siteId}/trivia-game-action-bar-click`,
        request
      );

      await IntegrationService.staticTrackEventAsync(
        'card-trivia-action-bar-click',
        {
          siteId: siteId,
          pageId: cacheState.pageId,
          pageSlug: cacheState.pageSlug,
          ...request,
          ...trackingData
        },
        site
      );
    } catch (e) {
      console.error('Error tracking trivia action bar click', e);
      //Ignore
    }
  }

  public static trackCouponViewedEventAsync = (couponId: Guid) => {
    try {
      return PublicLiveApi.post(`/site-events/coupons/${couponId}/viewed`);
    } catch (e) {
      console.error('Error tracking coupon viewed', e);
      return Promise.resolve();
      //Ignore
    }
  };
}
