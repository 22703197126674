import { FCC } from 'fcc';
import React, { useMemo, useState } from 'react';

import { useWindowSize } from '@/common/hooks/useWindowSize';

import { Box, BoxProps } from '../Display';
import { CircleWipeContainer } from '../Display/CircleWipeContainer';
import classes from './ModalContainer.module.css';
import { ModalSizes } from './ModalSizes';

const getModalStyle = (size: ModalSizes): BoxProps => {
  switch (size) {
    case ModalSizes.Small:
      return {
        style: { maxWidth: '400px', maxHeight: 'calc(100% - 4rem)' },
        __vars: {
          '--modal-max-width': '400px'
        }
      };
    case ModalSizes.Large:
      return {
        style: {
          maxWidth: '900px',
          maxHeight: 'calc(100% - 4rem)'
        },
        __vars: {
          '--modal-max-width': '900px'
        }
      };
    case ModalSizes.XLarge:
      return {
        style: {
          maxWidth: '1700px',
          height: 'calc(100% - 4rem)',
          maxHeight: 'calc(100% - 4rem)'
        },
        __vars: {
          '--modal-max-width': '1700px'
        }
      };
    case ModalSizes.FullScreen:
      return {
        style: {
          width: '100%',
          height: '100%',
          flex: '1 1 auto'
        },
        __vars: {
          '--modal-max-width': '100%'
        }
      };
    case ModalSizes.Normal:
    default:
      return {
        style: { maxWidth: '600px', maxHeight: 'calc(100% - 4rem)' },
        __vars: {
          '--modal-max-width': '600px'
        }
      };
  }
};

interface Props extends BoxProps {
  backgroundColor?: string;
  modalSize?: ModalSizes;
  dataHelpId?: string;
  circleWipeProps?: {
    enabled: boolean;
    color?: string;
  };
}

const MobileMax = 414;

export const ModalContainer: FCC<Props> = ({
  modalSize,
  children,
  dataHelpId,
  circleWipeProps,
  backgroundColor,
  ...rest
}) => {
  const [inTransition, setInTransition] = useState(!!circleWipeProps?.enabled);
  const [showMainContent, setShowMainContent] = useState(!inTransition);

  const { width } = useWindowSize();
  const styles = useMemo(() => {
    if (
      modalSize !== ModalSizes.Small &&
      modalSize !== ModalSizes.Normal &&
      width <= MobileMax
    ) {
      return getModalStyle(ModalSizes.FullScreen);
    }
    return getModalStyle(modalSize || ModalSizes.Normal);
  }, [modalSize, width]);
  const isFullScreen = modalSize === ModalSizes.FullScreen;
  return (
    <>
      {inTransition && (
        <CircleWipeContainer
          fireOnInit
          onRest={() => {
            setShowMainContent(true);
            setTimeout(() => {
              setInTransition(false);
            }, 200);
          }}
          bgColor={circleWipeProps?.color}
        />
      )}
      {showMainContent && (
        <Box
          data-is-fullscreen={isFullScreen}
          bg={backgroundColor || 'var(--mantine-color-body)'}
          className={classes.container}
          {...styles}
          {...rest}
          data-help-id={dataHelpId}
        >
          {children}
        </Box>
      )}
    </>
  );
};
