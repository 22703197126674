import { forwardRef } from 'react';

import { IAmInputRenderProps } from '../Renderers';
import { FormNumberInput, FormNumberInputProps } from './FormNumberInput';

export interface FormCurrencyInputProps
  extends Omit<FormNumberInputProps, 'suffix' | 'prefix'>,
    IAmInputRenderProps<HTMLInputElement> {}

export const FormCurrencyInput = forwardRef<
  HTMLInputElement,
  FormNumberInputProps
>(
  (
    {
      inputMode = 'decimal',
      decimalScale = 2,
      fixedDecimalScale = true,
      ...rest
    },
    forwardedRef
  ) => {
    return (
      <FormNumberInput
        ref={forwardedRef}
        {...rest}
        inputMode={inputMode}
        fixedDecimalScale={fixedDecimalScale}
        decimalScale={decimalScale}
        prefix="$"
      />
    );
  }
);
