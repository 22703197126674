import { BlobSignResponse } from '@/common/models/BlobSignResponse';
import { FormField } from '@/common/models/form/FormField';
import { uploadAsync } from '@/common/utils/FileFunctions';
import { PublicLiveApi } from '@/front/data/PublicApi';

export class PublicFormService {
  /**
   * Uploads one or more files for a form.
   * @param field The form field to upload the files to.
   * @param files The files to upload.
   * @returns An array of the file paths that were saved.
   */
  public static async uploadFiles(
    field: FormField,
    files: File[]
  ): Promise<string[]> {
    const filePaths = [];
    for (const file of files) {
      const paths = await PublicLiveApi.post<BlobSignResponse>(
        `forms/${field.formId}/field/${field.id}/sign-file`,
        { fileName: file.name }
      );
      await uploadAsync(paths.uploadUrl, file);
      filePaths.push(paths.readUrl);
    }

    return filePaths;
  }

  public static async uploadReceiptFiles(
    field: FormField,
    files: File[]
  ): Promise<string[]> {
    const filePaths = [];
    for (const file of files) {
      const paths = await PublicLiveApi.post<BlobSignResponse>(
        `forms/${field.formId}/sign-receipt-file`,
        { fileName: file.name }
      );
      await uploadAsync(paths.uploadUrl, file);
      filePaths.push(paths.readUrl);
    }

    return filePaths;
  }
}
