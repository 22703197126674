import { FieldValues } from 'react-hook-form';

import { numberValidator } from '../../_shared';
import { FormHookPercentInput } from '../../FormNumberInput';
import {
  DynamicFormContextData,
  IAmDynamicFormFieldProps,
  NumberInputDynamicFieldOptions
} from '../types';
import { DynamicFormHookField } from './__DynamicFormHookField';

const extraValidators = [numberValidator()];

export function DynamicFormPercentField<
  TFieldValues extends FieldValues = FieldValues,
  TContextData extends DynamicFormContextData = DynamicFormContextData
>({
  field,
  disabled,
  contextData,
  ...rest
}: IAmDynamicFormFieldProps<TFieldValues, TContextData>) {
  return (
    <DynamicFormHookField<TFieldValues, TContextData>
      field={field}
      disabled={disabled}
      contextData={contextData}
      extraValidators={extraValidators}
    >
      {(h) => (
        <FormHookPercentInput
          hook={h}
          {...(field.data.options as NumberInputDynamicFieldOptions)}
          {...rest}
        />
      )}
    </DynamicFormHookField>
  );
}
