import { createContext, useContext } from 'react';

import { CardSkeleton } from '@/common/components/Card/shared/Skeletons/CardSkeleton';
import { CardTypes } from '@/common/models/CardTypes';
import { HeightWidth } from '@/common/models/HeightWidth';

export const LoadingCardDescriptorContext = createContext<{
  type: CardTypes;
  imageAspectRatio?: HeightWidth;
  shellBg?: string;
  shellBd?: string;
}>(undefined);

/**
 * A component that you can reference inside the `loading:` part of a NextJS `dynamic` import
 */
export const LoadingContextCardSkeleton = () => {
  const { type, imageAspectRatio, shellBd, shellBg } = useContext(
    LoadingCardDescriptorContext
  );

  return (
    <CardSkeleton
      backgroundColor={shellBg}
      borderColor={shellBd}
      type={type}
      imageAspectRatio={imageAspectRatio}
    />
  );
};
