export enum CompetitionEntryResultCodes {
  Successful = 'Successful',
  MissingField = 'MissingField',
  InvalidField = 'InvalidField',
  AlreadyEntered = 'AlreadyEntered',
  InvalidEntry = 'InvalidEntry',
  Throttled = 'Throttled',
  ExistingRegistration = 'ExistingRegistration',
  UnhandledException = 'UnhandledException'
}
