import isNil from 'lodash/isNil';
import { FieldValues } from 'react-hook-form';

import {
  asFloatOrUndefined,
  asNumberOrUndefined
} from '@/common/utils/NumberFunctions';

import { IAmFormHookField } from '../_FormHookField';
import { FormCurrencyInput, FormCurrencyInputProps } from './FormCurrencyInput';

export interface FormHookCurrencyInputProps<
  TFieldValues extends FieldValues = any
> extends IAmFormHookField<TFieldValues>,
    Omit<
      FormCurrencyInputProps,
      'required' | 'name' | 'value' | 'defaultValue' | 'error' | 'disabled'
    > {
  isFloat?: boolean;
}

export function FormHookCurrencyInput<
  TFieldValues extends FieldValues = FieldValues
>(props: FormHookCurrencyInputProps<TFieldValues>) {
  const { hook, onBlur, onChange, isFloat, ...rest } = props;

  const value = isFloat
    ? asFloatOrUndefined(hook.field.value)
    : asNumberOrUndefined(hook.field.value);

  return (
    <FormCurrencyInput
      {...rest}
      disabled={hook.disabled}
      required={hook.required}
      name={hook.field.name}
      ref={hook.field.ref}
      onBlur={(e) => {
        hook.field.onBlur();
        onBlur?.(e);
      }}
      onChange={(v) => {
        hook.setValue(hook.field.name, v as any, {
          shouldValidate: true,
          shouldDirty: true
        });
        onChange?.(v);
      }}
      value={isNil(value) ? '' : value}
      error={hook.showError ? hook.errorMessage : undefined}
    />
  );
}
