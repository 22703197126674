import { createContext, useContext } from 'react';

import { KeyOf } from '@/common/models/KeyOf';
import { ThemeColors } from '@/theme';

interface ListContextProps {
  itemsHaveShadow?: boolean;
  itemsSelectable?: boolean;
  itemsTextColorVariant?: KeyOf<ThemeColors> | string;
  itemsHaveBackgroundColor?: boolean;
  itemsBorder?: string;
  itemsPadding?: string;
}

export const ListContext = createContext<ListContextProps>({
  itemsHaveShadow: false,
  itemsSelectable: false
});

export const useListContext = () => useContext(ListContext);
