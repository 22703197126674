import React, { forwardRef, useMemo } from 'react';
import InputMask from 'react-input-mask';

import { useUncontrolled } from '@/common/hooks/useUncontrolled';
import { getLocaleDateString } from '@/common/utils/DateFunctions';

import { FormTextInput, FormTextInputProps } from '../FormTextInput';

export interface FormDateMaskInputProps
  extends Omit<
    FormTextInputProps,
    'placeholder' | 'sanitiseValue' | 'inputMode'
  > {
  locale?: string;
}

export const FormDateMaskInput = forwardRef<
  HTMLInputElement,
  FormDateMaskInputProps
>(
  (
    {
      value: valueProp,
      defaultValue,
      disabled,
      onChange,
      onBlur,
      locale,
      ...rest
    },
    ref
  ) => {
    const [value, handleChange] = useUncontrolled<
      string,
      React.ChangeEvent<HTMLInputElement>
    >({
      value: valueProp,
      defaultValue,
      finalValue: null,
      resolveValue: (e) => e.target.value,
      onChange
    });

    const dateFormat = useMemo(() => getLocaleDateString(locale), [locale]);

    return (
      <InputMask
        mask={dateFormat.replace(/[dMy]/g, '9')}
        maskChar={null}
        value={value}
        onChange={handleChange}
        disabled={disabled}
        onBlur={onBlur}
        placeholder={dateFormat.toUpperCase()}
        alwaysShowMask
      >
        {(maskProps) => (
          <FormTextInput
            ref={ref}
            {...rest}
            disabled={disabled}
            {...maskProps}
            inputMode="numeric"
          />
        )}
      </InputMask>
    );
  }
);
