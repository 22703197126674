import { OptionalThemeProvider, ThemeProviderProps } from '_theme';
import { FC } from 'react';

import { Box } from '@/common/components/Display/Box';
import { Group } from '@/common/components/Display/Group';
import { KomoLoader, Loader } from '@/common/components/Loader';

export interface LoadingPageProps
  extends Pick<ThemeProviderProps, 'forceColorScheme'> {
  backgroundImage?: {
    url: string;
    opacity?: number;
  };
  variant?: 'spinner' | 'komo';
  withThemeProvider?: boolean;
}
export const LoadingPage: FC<LoadingPageProps> = ({
  backgroundImage,
  variant = 'spinner',
  withThemeProvider = false,
  forceColorScheme
}) => {
  return (
    <OptionalThemeProvider
      forceColorScheme={forceColorScheme}
      withTheme={withThemeProvider}
    >
      {backgroundImage && (
        <Box
          bg={`url(${backgroundImage.url})`}
          opacity={backgroundImage.opacity}
          w={'100vw'}
          h={'100vh'}
          pos={'absolute'}
          style={{ zIndex: -1 }}
        />
      )}
      <Group h={'100vh'} align="center" justify="center" gap={0}>
        {variant === 'komo' ? <KomoLoader /> : <Loader size={'3rem'} />}
      </Group>
    </OptionalThemeProvider>
  );
};
