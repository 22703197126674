import {
  Pill as MantinePill,
  PillProps as MantinePillProps
} from '@mantine/core';
import cx from 'clsx';

import { OptionModel } from '@/common/models/OptionModel';

import classes from './_Components.module.css';
import { MultiSelectValueComponentProps } from './_types';

export interface MultiSelectPillProps<TOption extends OptionModel = OptionModel>
  extends MantinePillProps {
  value: TOption;
  component?: MultiSelectValueComponentProps<TOption>;
}

export function MultiSelectPill<TOption extends OptionModel = OptionModel>({
  value,
  bg = 'light-dark(var(--mantine-color-white), var(--mantine-color-dark-7))',
  component,
  size = 'sm',
  className,
  withRemoveButton = true,
  ...rest
}: MultiSelectPillProps<TOption>) {
  const hasIcon = !!component?.icon;

  return (
    <MantinePill
      bg={bg}
      size={size}
      className={cx(classes.multiSelectPill, className)}
      withRemoveButton={withRemoveButton}
      {...rest}
    >
      {hasIcon && (
        <div className={classes.multiSelectPillIcon}>
          {component.icon(value)}
        </div>
      )}
      {component?.label?.(value) ?? value.label}
    </MantinePill>
  );
}
