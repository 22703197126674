import { FCC } from 'fcc';
import { useRouter } from 'next/router';
import { ReactNode, useEffect, useState } from 'react';
import {
  createContext,
  useContext,
  useContextSelector
} from 'use-context-selector';

import { CardSkeleton } from '@/common/components/Card/shared/Skeletons/CardSkeleton';
import { useUser } from '@/common/components/UserContext';
import {
  useDeepCompareEffect,
  useDeepCompareMemo
} from '@/common/hooks/useDeepCompareEffects';
import { CardDescriptor } from '@/common/models/CardDescriptor';
import {
  CompetitionCheckCodes,
  CompetitionCheckCompletedPlay
} from '@/common/models/competitions';
import { Guid } from '@/common/models/Guid';
import { NoOp } from '@/common/utils/NoOp';
import { PublicCompetitionService } from '@/front/data/PublicCompetitionService';

import { useFrontSite } from './SiteHost/Store';

export interface UserInteractContextState {
  cardQueryParamMatchesCard: boolean;
  canEnter: boolean;
  completedPlay?: CompetitionCheckCompletedPlay;
  requiredFields: string[];
  setHasFinishedInteract: (v: boolean, correctAnswers?: number) => void;
  hasFinishedInteract: boolean;
  competitionCheckCode: CompetitionCheckCodes;
  existingRegistrationEntryId?: Guid;
  setExistingRegistration: (entryId: Guid) => void;
}

export const UserInteractContext = createContext<UserInteractContextState>({
  cardQueryParamMatchesCard: false,
  canEnter: false,
  requiredFields: [],
  hasFinishedInteract: false,
  completedPlay: undefined,
  setHasFinishedInteract: NoOp,
  competitionCheckCode: CompetitionCheckCodes.Unknown,
  existingRegistrationEntryId: undefined,
  setExistingRegistration: NoOp
});
UserInteractContext.displayName = 'UserInteractContext';

interface Props {
  cardDescriptor: CardDescriptor;
  children: ReactNode;
}

interface State {
  initFinished: boolean;
  competitionCheckCode: CompetitionCheckCodes;
  completedPlay: CompetitionCheckCompletedPlay;
  requiredFields: string[];
  hasFinishedInteract: boolean;
  existingRegistrationEntryId?: Guid;
}

export const UserInteractProvider: FCC<Props> = ({
  cardDescriptor,
  children
}) => {
  const { userId, profileId } = useUser();
  const { query } = useRouter();
  const siteId = useFrontSite((x) => x.site.id);
  const [state, setState] = useState<State>({
    initFinished: false,
    competitionCheckCode: CompetitionCheckCodes.Unknown,
    completedPlay: null,
    requiredFields: [],
    hasFinishedInteract: false,
    existingRegistrationEntryId: undefined
  });

  const [cardQueryParamMatchesCard, setCardQueryParamMatchesCard] =
    useState(false);

  useEffect(() => {
    (async () => {
      if (!cardDescriptor?.competitionId) {
        setState((s) => ({
          ...s,
          competitionCheckCode: CompetitionCheckCodes.CanEnter,
          conditionalEntryCheckPassed: false,
          requiredFields: [],
          completedPlay: null,
          initFinished: true,
          existingRegistrationEntryId: undefined
        }));
      } else {
        const result = await PublicCompetitionService.checkEntryAsync(
          siteId,
          cardDescriptor.competitionId,
          userId
        );

        setState((s) => ({
          ...s,
          competitionCheckCode: result.code,
          completedPlay: result.completed,
          requiredFields: result.requiredFields,
          initFinished: true,
          existingRegistrationEntryId: result.existingRegistrationEntryId
        }));
      }
    })();
  }, [
    cardDescriptor?.competitionId?.toString(),
    userId?.toString(),
    siteId?.toString(),
    profileId?.toString() // profile id is required here to ensure required fields are loaded on user login
  ]);

  useDeepCompareEffect(() => {
    const cardId = cardDescriptor?.id?.toString();
    const cardIdFromQuery = query?.card as string;
    if (!cardId || !cardIdFromQuery) {
      setCardQueryParamMatchesCard(false);
    } else {
      setCardQueryParamMatchesCard(cardId === cardIdFromQuery);
    }
  }, [query, cardDescriptor]);

  const setExistingEntry = (entryId: Guid) => {
    setState((s) => ({
      ...s,
      existingRegistrationEntryId: entryId,
      competitionCheckCode: CompetitionCheckCodes.ExistingRegistration
    }));
  };

  const value = useDeepCompareMemo<UserInteractContextState>(() => {
    const v: UserInteractContextState = {
      hasFinishedInteract: state.hasFinishedInteract,
      setHasFinishedInteract: (f) =>
        setState((s) => ({ ...s, hasFinishedInteract: f })),
      cardQueryParamMatchesCard,
      canEnter: state.competitionCheckCode === CompetitionCheckCodes.CanEnter,
      completedPlay: state.completedPlay,
      requiredFields: state.requiredFields,
      competitionCheckCode: state.competitionCheckCode,
      existingRegistrationEntryId: state.existingRegistrationEntryId,
      setExistingRegistration: setExistingEntry
    };
    return v;
  }, [state]);

  return (
    <UserInteractContext.Provider value={value}>
      {!state.initFinished && (
        <CardSkeleton
          type={cardDescriptor.type}
          imageAspectRatio={cardDescriptor.CoverImage?.size}
          backgroundColor={cardDescriptor.properties.ShellBg}
          borderColor={cardDescriptor.properties.ShellBd}
        />
      )}
      {state.initFinished && children}
    </UserInteractContext.Provider>
  );
};

export const useUserInteract = (): UserInteractContextState => {
  const context = useContext(UserInteractContext);
  if (context === undefined) {
    throw new Error(
      'userUserInteract must be used within a UserInteractProvider'
    );
  }
  return context;
};

export function useUserInteractSelector<T>(
  selector: (value: UserInteractContextState) => T
): T {
  return useContextSelector(UserInteractContext, (state) => {
    if (state === undefined) {
      throw new Error('useUserContext must be used within a UserProvider');
    }
    return selector(state);
  });
}
