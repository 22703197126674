import { FC } from 'react';

import { IconProps } from '../Icons/IconProps';
import { IconSvg } from '../Icons/IconSvg';

export const LinkedInIcon: FC<IconProps> = ({ color, ...rest }) => {
  return (
    <IconSvg {...rest}>
      <g clipPath="url(#clip0)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12 0a12 12 0 100 24 12 12 0 000-24zM8.33 3.13a9.6 9.6 0 117.34 17.74A9.6 9.6 0 018.33 3.13zM7.38 16.8h2.24V9.59H7.38v7.21zM7.2 7.3c0 .71.58 1.3 1.3 1.3a1.3 1.3 0 10-1.3-1.3zm8.07 9.5h2.24v-3.96c0-1.94-.42-3.4-2.68-3.4-1.1 0-1.72.57-2.02 1.13h-.03V9.6h-2.14v7.21h2.23v-3.57c0-.94.07-1.85 1.24-1.85 1.15 0 1.16 1.07 1.16 1.91v3.51z"
          fill={color || 'currentColor'}
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <path fill={color || 'currentColor'} d="M0 0h24v24H0z" />
        </clipPath>
      </defs>
    </IconSvg>
  );
};
