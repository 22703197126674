import { FC, memo } from 'react';

import { KomoLogoIcon } from '@/common/components/Icons/KomoLogoIcon';
import defaultSiteFont from '@/common/constants/defaultSiteFont.module.css';
import { MarketingUrls } from '@/common/utils/Constants';

import style from './PoweredBy.module.css';

export const PoweredBy: FC = memo(() => {
  return (
    <a
      className={style.poweredByBox}
      href={MarketingUrls.site({
        utm: { type: 'Site', medium: 'Branding button' }
      })}
      target="_blank"
    >
      <div className={style.poweredBadgeWrapper}>
        <div
          className={`${defaultSiteFont.defaultSiteFont} ${style.poweredByText}`}
        >
          Powered by
        </div>
        <KomoLogoIcon className={style.poweredByLogo} />
      </div>
    </a>
  );
});
