import { FC } from 'react';

import { SocialIcon } from '@/common/components/social-icons';
import { SocialNetworks } from '@/common/components/social-icons/SocialNetworks';

import style from './SiteFooterSocial.module.css';

interface Props {
  textColor: string;
  facebookUrl?: string;
  twitterUrl?: string;
  instagramUrl?: string;
  linkedinUrl?: string;
  youtubeUrl?: string;
  webSiteUrl?: string;
  tikTokUrl?: string;
  snapchatUrl?: string;
  redditUrl?: string;
  pinterestUrl?: string;
}

export const SiteFooterSocial: FC<Props> = ({ textColor, ...rest }) => {
  const sanitiseLink = (url: string) => {
    let linkToOpen = url;
    if (!linkToOpen.match(/^https?:\/\//i)) {
      linkToOpen = `https://${url}`;
    }
    return linkToOpen;
  };

  const IconSize = '2.25rem';

  const urls = Object.values(rest);
  if (urls.every((x) => !x)) return null;

  return (
    <div className={style.footerSocialWrapper}>
      {!!rest.facebookUrl && (
        <a
          className={style.footerSocialIcon}
          href={sanitiseLink(rest.facebookUrl)}
          target="_blank"
        >
          <SocialIcon
            color={textColor as string}
            network={SocialNetworks.Facebook}
            size={IconSize}
          />
        </a>
      )}
      {!!rest.twitterUrl && (
        <a
          className={style.footerSocialIcon}
          href={sanitiseLink(rest.twitterUrl)}
          target="_blank"
        >
          <SocialIcon
            color={textColor as string}
            network={SocialNetworks.Twitter}
            size={IconSize}
          />
        </a>
      )}
      {!!rest.instagramUrl && (
        <a
          className={style.footerSocialIcon}
          href={sanitiseLink(rest.instagramUrl)}
          target="_blank"
        >
          <SocialIcon
            color={textColor as string}
            network={SocialNetworks.Instagram}
            size={IconSize}
          />
        </a>
      )}
      {!!rest.linkedinUrl && (
        <a
          className={style.footerSocialIcon}
          href={sanitiseLink(rest.linkedinUrl)}
          target="_blank"
        >
          <SocialIcon
            color={textColor as string}
            network={SocialNetworks.Linkedin}
            size={IconSize}
          />
        </a>
      )}
      {!!rest.youtubeUrl && (
        <a
          className={style.footerSocialIcon}
          href={sanitiseLink(rest.youtubeUrl)}
          target="_blank"
        >
          <SocialIcon
            color={textColor as string}
            network={SocialNetworks.Youtube}
            size={IconSize}
          />
        </a>
      )}
      {!!rest.webSiteUrl && (
        <a
          className={style.footerSocialIcon}
          href={sanitiseLink(rest.webSiteUrl)}
          target="_blank"
        >
          <SocialIcon
            color={textColor as string}
            network={SocialNetworks.Website}
            size={IconSize}
          />
        </a>
      )}
      {!!rest.tikTokUrl && (
        <a
          className={style.footerSocialIcon}
          href={sanitiseLink(rest.tikTokUrl)}
          target="_blank"
        >
          <SocialIcon
            color={textColor as string}
            network={SocialNetworks.TikTok}
            size={IconSize}
          />
        </a>
      )}

      {!!rest.snapchatUrl && (
        <a
          className={style.footerSocialIcon}
          href={sanitiseLink(rest.snapchatUrl)}
          target="_blank"
        >
          <SocialIcon
            color={textColor as string}
            network={SocialNetworks.Snapchat}
            size={IconSize}
          />
        </a>
      )}
      {!!rest.redditUrl && (
        <a
          className={style.footerSocialIcon}
          href={sanitiseLink(rest.redditUrl)}
          target="_blank"
        >
          <SocialIcon
            color={textColor as string}
            network={SocialNetworks.Reddit}
            size={IconSize}
          />
        </a>
      )}
      {!!rest.pinterestUrl && (
        <a
          className={style.footerSocialIcon}
          href={sanitiseLink(rest.pinterestUrl)}
          target="_blank"
        >
          <SocialIcon
            color={textColor as string}
            network={SocialNetworks.Pinterest}
            size={IconSize}
          />
        </a>
      )}
    </div>
  );
};
