import { QueryClient, useQueryClient } from '@tanstack/react-query';
import { useRef } from 'react';

import { ClientRequestInfo } from '@/common/models/ClientRequestInfo';
import { Site } from '@/common/models/site';
import { PublicSiteService } from '@/front/data/Sites/PublicSiteService';

import { FrontSiteStore } from './FrontSiteStore';

interface Options {
  initAction?: (queryClient: QueryClient) => void;
  site: Site;
  clientRequestInfo?: ClientRequestInfo;
}
export const useInitFrontSite = ({
  site,
  clientRequestInfo,
  initAction
}: Options) => {
  const queryClient = useQueryClient();
  const hasRun = useRef<boolean>(false);
  if (!hasRun.current) {
    hasRun.current = true;
    try {
      queryClient.setQueriesData(
        PublicSiteService.cacheKeys.get(site.id),
        site
      );
      FrontSiteStore.setState({
        site,
        clientRequestInfo: new ClientRequestInfo(clientRequestInfo)
      });
      initAction?.(queryClient);
    } catch (e) {
      console.error(e, 'Error initializing FrontSite');
    }
  }

  PublicSiteService.hooks.useSiteSync({
    siteId: site.id,
    onSiteChange: (s) => {
      FrontSiteStore.setState({ site: s });
    }
  });
};
