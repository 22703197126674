import { useRef } from 'react';
import ReactModal from 'react-modal';

import { FeedbackProvider } from '@/common/components/Feedback';
import { useEvent } from '@/common/hooks/useEvent';

import { BoxProps } from '../Display';
import { ModalContainer } from './ModalContainer';
import { ModalContent } from './ModalContent';
import { ModalFooter } from './ModalFooter';
import { ModalHeader } from './ModalHeader';
import { ModalSidebarLayout } from './ModalSidebar';
import { ModalSizes } from './ModalSizes';
import { ModalStateProvider } from './ModalStateProvider';

export interface ModalContainerStyles extends BoxProps {
  backgroundColor?: string;
}

export interface ModalProps extends Omit<ReactModal.Props, 'isOpen'> {
  size?: ModalSizes;
  modalStyles?: ModalContainerStyles;
  overlayStyles?: { backgroundColor?: string };
  isOpen?: boolean;
  dataHelpId?: string;
  circleWipeProps?: {
    enabled: boolean;
    color?: string;
  };
}

export function Modal({
  isOpen = true,
  size,
  modalStyles,
  overlayStyles,
  children,
  dataHelpId,
  circleWipeProps,
  ...rest
}: ModalProps) {
  const overlayRef = useRef<HTMLDivElement>();
  const contentRef = useRef<HTMLDivElement>();

  const registerContent = useEvent((e: HTMLDivElement) => {
    if (!contentRef.current) {
      contentRef.current = e;
    }
  });

  const registerOverlay = useEvent((e: HTMLDivElement) => {
    if (!overlayRef.current) {
      overlayRef.current = e;
    }
  });

  return (
    <ReactModal
      overlayRef={registerOverlay}
      contentRef={registerContent}
      style={{
        content: {
          position: 'relative',
          inset: 'initial',
          background: 'none',
          outline: 'none',
          border: 0,
          borderRadius: 0,
          margin: 0,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          width: '100%',
          height: '100%',
          overflow: 'hidden',
          zIndex: 1101,
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
          padding: size !== ModalSizes.FullScreen ? '0 0.5rem' : '0'
        },
        overlay: {
          backgroundColor: 'rgba(0, 0, 0, 0.2)',
          zIndex: 1100,
          ...overlayStyles
        }
      }}
      isOpen={isOpen}
      shouldCloseOnEsc={true}
      shouldCloseOnOverlayClick={true}
      shouldFocusAfterRender={true}
      {...rest}
    >
      <ModalStateProvider
        contentRef={contentRef}
        overlayRef={overlayRef}
        size={size}
      >
        <FeedbackProvider>
          <ModalContainer
            modalSize={size}
            circleWipeProps={circleWipeProps}
            {...modalStyles}
            dataHelpId={dataHelpId}
          >
            {children}
          </ModalContainer>
        </FeedbackProvider>
      </ModalStateProvider>
    </ReactModal>
  );
}

Modal.Header = ModalHeader;
Modal.Content = ModalContent;
Modal.Footer = ModalFooter;
Modal.SidebarLayout = ModalSidebarLayout;
