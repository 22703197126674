export type LabelSizeVariant = 'Normal' | 'Small' | 'Large';

export class LabelStyler {
  static fontSize(sizeVariant?: LabelSizeVariant) {
    switch (sizeVariant) {
      case 'Small':
        return '12px';
      case 'Large':
        return '16px';
      case 'Normal':
      default:
        return '14px';
    }
  }

  static marginBottom = '4px';
}
