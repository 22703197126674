import { FC } from 'react';

import { SaveIcon } from '@/common/components/Icons/SaveIcon';
import { Loader } from '@/common/components/Loader';
import { useActionHandler } from '@/common/hooks/useActionHandler';

import { Button } from '../Button';
import { Box } from '../Display';
import { useFeedback } from './FeedbackContext';
import { FeedbackDisplay } from './FeedbackDisplay';

interface Props {
  busyText?: string;
  idleText?: string;
  unsavedText?: string;
  unsavedWithCallbackText?: string;
}

export const FeedbackContent: FC<Props> = ({
  busyText,
  idleText,
  unsavedText,
  unsavedWithCallbackText
}) => {
  const { isBusy, unSavedState, setUnSavedState } = useFeedback();
  const [handleAsync, { isHandling }] = useActionHandler();

  const showUnSavedWithCallback =
    unSavedState.hasChanges && !!unSavedState.callback;
  const showUnsaved = !isBusy && unSavedState.hasChanges;
  const showSaved = !isBusy && !showUnsaved;

  return (
    <>
      {showUnSavedWithCallback && (
        <Box>
          <Button
            onClick={() =>
              handleAsync(unSavedState.callback, {
                noToastOnError: true,
                onSuccess: () => {
                  if (unSavedState.clearStateOnCallbackSuccess) {
                    setUnSavedState((x) => ({
                      ...x,
                      hasChanges: false,
                      callback: undefined
                    }));
                  }
                }
              })
            }
            busy={isHandling || isBusy}
            variant="filled"
            style={{ padding: '0.5rem' }}
          >
            <SaveIcon mr="0.5rem" />
            {unsavedWithCallbackText || 'Save'}
          </Button>
        </Box>
      )}
      {!showUnSavedWithCallback && isBusy && (
        <FeedbackDisplay>
          <Loader />
          <span>{busyText || 'Saving'}</span>
        </FeedbackDisplay>
      )}
      {!isBusy && !showUnSavedWithCallback && (
        <FeedbackDisplay>
          <SaveIcon size={'0.8rem'} />
          <span>
            {showUnsaved && (unsavedText || 'Unsaved')}
            {showSaved && (idleText || 'Saved')}
          </span>
        </FeedbackDisplay>
      )}
    </>
  );
};
