import {
  Space as MantineSpace,
  SpaceProps as MantineSpaceProps
} from '@mantine/core';

/**
 * @see https://mantine.dev/core/space/
 */
export const Space = MantineSpace;
export type SpaceProps = MantineSpaceProps;
