import { useRef } from 'react';
import isEqual from 'react-fast-compare';
import {
  Context,
  createContext as cc,
  useContextSelector as useContextSelectorNormal
} from 'use-context-selector';

export const invalidContext = () => {
  throw new Error('Cant use outside context');
};

export const createContext = cc;

export type EqualityVariant = 'Reference' | 'Value';

export function useContextSelector<TContextValue, TSelectValue>(
  context: Context<TContextValue>,
  selector: (value: TContextValue) => TSelectValue,
  equalityVariant: EqualityVariant
) {
  const useReferenceEqualityRef = useRef(equalityVariant || 'Reference');

  const hook =
    useReferenceEqualityRef.current === 'Reference'
      ? useContextSelectorNormal
      : useContextSelectorWithValueEquality;
  return hook(context, selector);
}

function useContextSelectorWithValueEquality<TContextValue, TSelectValue>(
  context: Context<TContextValue>,
  selector: (value: TContextValue) => TSelectValue
) {
  const prevValue = useRef(null);
  const patchedSelector = (state) => {
    const nextValue = selector(state);
    if (isEqual(prevValue.current, nextValue)) {
      return prevValue.current;
    }
    return (prevValue.current = nextValue);
  };

  return useContextSelectorNormal(context, patchedSelector);
}
