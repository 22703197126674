import { asBoolean } from '@/common/utils/BooleanFunctions';

export class SiteMenuItemProperties {
  OpenInNewTab: boolean;

  constructor(props?: Partial<SiteMenuItemProperties>) {
    props = props || {};
    Object.assign(this, props);
    this.OpenInNewTab = asBoolean(props.OpenInNewTab);
  }
}
