import { QueryClient, useQueryClient } from '@tanstack/react-query';
import { useRef } from 'react';

import { Page } from '@/common/models/pages/Page';
import { PublicPageService } from '@/front/data/Pages/PublicPageService';

import { FrontPageStore } from './FrontPageStore';

interface Options {
  initAction?: (queryClient: QueryClient) => void;
  page: Page;
}
export const useInitFrontPage = ({ page, initAction }: Options) => {
  const queryClient = useQueryClient();
  const hasRun = useRef<boolean>(false);

  if (page) {
    if (!hasRun.current) {
      hasRun.current = true;
      try {
        queryClient.setQueriesData(
          PublicPageService.cacheKeys.get(page.id),
          page
        );
        FrontPageStore.setState({
          page
        });
        initAction?.(queryClient);
      } catch (e) {
        console.error(e, 'Error initializing FrontPage');
      }
    }

    PublicPageService.hooks.usePageSync({
      pageId: page.id,
      onPageChange: (s) => {
        FrontPageStore.setState({ page: s });
      }
    });
  }
};
