import { nanoid } from 'nanoid';

import { SessionStorageHelper } from '@/common/utils/SessionStorageHelper';

/**
 * Identifies the tab in the browser.
 */
const tabIdKey = 'komo:tid';

export const SessionV2SessionStorageHelper = {
  getTabId: () => SessionStorageHelper.getOrCreateItem(tabIdKey, () => nanoid())
};
