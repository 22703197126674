import { forwardRef } from 'react';

import { IconProps } from './IconProps';
import { IconSvg } from './IconSvg';

export const QRCodeIcon = forwardRef<HTMLSpanElement, IconProps>(
  ({ color, ...rest }, ref) => {
    return (
      <IconSvg ref={ref} {...rest}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5 11H9C10.1 11 11 10.1 11 9V5C11 3.9 10.1 3 9 3H5C3.9 3 3 3.9 3 5V9C3 10.1 3.9 11 5 11ZM5 5H9V9H5V5ZM5 21H9C10.1 21 11 20.1 11 19V15C11 13.9 10.1 13 9 13H5C3.9 13 3 13.9 3 15V19C3 20.1 3.9 21 5 21ZM5 15H9V19H5V15ZM13 9V5C13 3.9 13.9 3 15 3H19C20.1 3 21 3.9 21 5V9C21 10.1 20.1 11 19 11H15C13.9 11 13 10.1 13 9ZM15 9H19V5H15V9ZM21 20.5V19.5C21 19.22 20.78 19 20.5 19H19.5C19.22 19 19 19.22 19 19.5V20.5C19 20.78 19.22 21 19.5 21H20.5C20.78 21 21 20.78 21 20.5ZM13 14.5V13.5C13 13.22 13.22 13 13.5 13H14.5C14.78 13 15 13.22 15 13.5V14.5C15 14.78 14.78 15 14.5 15H13.5C13.22 15 13 14.78 13 14.5ZM16.5 15H15.5C15.22 15 15 15.22 15 15.5V16.5C15 16.78 15.22 17 15.5 17H16.5C16.78 17 17 16.78 17 16.5V15.5C17 15.22 16.78 15 16.5 15ZM13 18.5V17.5C13 17.22 13.22 17 13.5 17H14.5C14.78 17 15 17.22 15 17.5V18.5C15 18.78 14.78 19 14.5 19H13.5C13.22 19 13 18.78 13 18.5ZM15.5 21H16.5C16.78 21 17 20.78 17 20.5V19.5C17 19.22 16.78 19 16.5 19H15.5C15.22 19 15 19.22 15 19.5V20.5C15 20.78 15.22 21 15.5 21ZM18.5 19H17.5C17.22 19 17 18.78 17 18.5V17.5C17 17.22 17.22 17 17.5 17H18.5C18.78 17 19 17.22 19 17.5V18.5C19 18.78 18.78 19 18.5 19ZM18.5 13H17.5C17.22 13 17 13.22 17 13.5V14.5C17 14.78 17.22 15 17.5 15H18.5C18.78 15 19 14.78 19 14.5V13.5C19 13.22 18.78 13 18.5 13ZM20.5 17H19.5C19.22 17 19 16.78 19 16.5V15.5C19 15.22 19.22 15 19.5 15H20.5C20.78 15 21 15.22 21 15.5V16.5C21 16.78 20.78 17 20.5 17Z"
          fill={color || 'currentColor'}
        />
      </IconSvg>
    );
  }
);
