import { ElementProps, UnstyledButton } from '@mantine/core';
import cx from 'clsx';
import { CSSProperties, FC, ReactNode } from 'react';

import { Box, BoxProps } from '@/common/components/Display';
import { Image } from '@/common/components/Image';
import { FrontContactAvatar } from '@/common/models/contact-avatars/Front';
import { Guid } from '@/common/models/Guid';
import { useTheme } from '@/theme';

import classes from '../_ContactProfileHeaderButton.module.css';

const getAvatarSize = () => ({ width: 120, height: 120 });

interface Props extends Omit<AvatarGridProps, 'children'> {
  selectedId?: Guid;
  onSelected?: (id: Guid) => void;
  avatars: FrontContactAvatar[];
}

export const AvatarSelection: FC<Props> = ({
  selectedId,
  onSelected,
  avatars,
  ...rest
}) => {
  const theme = useTheme();
  return (
    <AvatarGrid {...rest}>
      {avatars.map((a) => {
        return (
          <UnstyledButton
            key={a.id.toString()}
            __vars={{
              '--avatar-selected-c':
                theme.other.site.accentBgColor || theme.black
            }}
            className={classes.avatarItem}
            data-selected={a.id.equals(selectedId)}
            onClick={() => onSelected?.(a.id)}
          >
            <Image
              image={a.image}
              onCalculateSize={getAvatarSize}
              objectFit={'contain'}
              aspectRatio={{ height: 1, width: 1 }}
            />
          </UnstyledButton>
        );
      })}
    </AvatarGrid>
  );
};

interface AvatarGridProps extends BoxProps, ElementProps<'div'> {
  children: ReactNode;
}

export const AvatarGrid: FC<AvatarGridProps> = ({
  className,
  children,
  ...rest
}) => {
  return (
    <Box className={cx(classes.avatarGrid, className)} {...rest}>
      {children}
    </Box>
  );
};
