import { FC } from 'react';

import { IconProps } from '../Icons/IconProps';
import { IconSvg } from '../Icons/IconSvg';

export const YoutubeIcon: FC<IconProps> = ({ color, ...rest }) => {
  return (
    <IconSvg {...rest}>
      <g clipPath="url(#clip0)" fill={color || 'currentColor'}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7.408.913a12 12 0 119.184 22.173A12 12 0 017.408.913zM12 2.4a9.6 9.6 0 100 19.2 9.6 9.6 0 000-19.2z"
        />
        <path d="M14.67 7.14c-1.36-.04-2.72-.05-4.081-.029-3.85.073-5.467.014-5.66 2.976a60.547 60.547 0 00-.01 3.757c.192 3.23 1.229 3.17 5.67 3.27 1.24.02 2.481.014 3.721-.018 3.124-.086 4.577-.01 4.77-3.254a60.57 60.57 0 00-.009-3.755c-.193-2.974-1.869-2.876-4.401-2.948zm-4.114 6.82c-.007-1.343-.007-2.687.002-4.03 1.25.663 2.512 1.345 3.772 2.016-1.259.668-2.522 1.35-3.774 2.014z" />
      </g>
      <defs>
        <clipPath id="clip0">
          <path fill={color || 'currentColor'} d="M0 0h24v24H0z" />
        </clipPath>
      </defs>
    </IconSvg>
  );
};
