export const ReactNativeWebViewName = 'ReactNativeWebView';

export const EmbeddedFunctions = {
  /**
   * Determines if the current window is within an iframe
   * in a safe cross browser model.
   *
   * @returns {Boolean} True if in an iframe, else false.
   */
  isInIframe() {
    try {
      return window.self !== window.top;
    } catch (e) {
      return true;
    }
  },

  // window.self === window.top in React Native WebView;
  isInReactNativeWebview() {
    try {
      return !!window?.[ReactNativeWebViewName];
    } catch {
      return false;
    }
  }
};
