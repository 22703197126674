import {
  MantineSpacing,
  MantineStyleProps,
  StyleProp,
  TypographyStylesProvider
} from '@mantine/core';
import { FC } from 'react';

import { HtmlRenderer } from './HtmlRenderer';

interface Props {
  html: string;
  color?: string;
  backgroundColor?: string;
  fontSize?: MantineStyleProps['fz'];
  padding?: StyleProp<MantineSpacing>;
}
/**
 * @see https://mantine.dev/core/typography-styles-provider/
 */

export const HtmlRendererV2: FC<Props> = ({
  html,
  color,
  backgroundColor,
  fontSize,
  padding
}) => {
  return (
    <TypographyStylesProvider fz={fontSize} p={padding}>
      <HtmlRenderer
        html={html}
        color={color}
        backgroundColor={backgroundColor}
      />
    </TypographyStylesProvider>
  );
};
