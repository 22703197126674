import { FieldValues } from 'react-hook-form';

import { maxCharLengthValidator, minCharLengthValidator } from '../../_shared';
import { FormHookPasswordInput } from '../../FormPasswordInput';
import { passwordValidator } from '../../FormValidators/_password';
import {
  DynamicFormContextData,
  IAmDynamicFormFieldProps,
  TextDynamicFieldOptions
} from '../types';
import { DynamicFormHookField } from './__DynamicFormHookField';

const extraValidators = [
  passwordValidator(),
  minCharLengthValidator(8),
  maxCharLengthValidator(30)
];

export function DynamicFormPasswordField<
  TFieldValues extends FieldValues = FieldValues,
  TContextData extends DynamicFormContextData = DynamicFormContextData
>({
  field,
  disabled,
  contextData,
  ...rest
}: IAmDynamicFormFieldProps<TFieldValues, TContextData>) {
  return (
    <DynamicFormHookField<TFieldValues, TContextData>
      field={field}
      disabled={disabled}
      contextData={contextData}
      extraValidators={extraValidators}
    >
      {(h) => (
        <FormHookPasswordInput
          hook={h}
          {...((field.data.options as TextDynamicFieldOptions) || {})}
          {...rest}
        />
      )}
    </DynamicFormHookField>
  );
}
