import { MantineSize, MantineSpacing } from '@mantine/core';

import { useWindowSize } from '@/common/hooks/useWindowSize';

export const DynamicFormHeaderSpacing: string = '1rem';
export const DynamicFormFieldSize: MantineSize = 'md';

export const useDynamicFormRendererSizes = () => {
  const { width } = useWindowSize();
  const isMobile = width <= 750;

  const headerSpacing: MantineSpacing = '0.5rem';
  const contentPadding = isMobile ? 'md' : '1.5rem';
  const fieldSize = DynamicFormFieldSize;
  const headerFieldSpacing = DynamicFormHeaderSpacing;

  return {
    isMobile,
    headerSpacing,
    contentPadding,
    fieldSize,
    headerFieldSpacing
  };
};
