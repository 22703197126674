import { ReactNode } from 'react';

import { BoxProps } from '@/common/components/Display/Box';
import { TextProps } from '@/common/components/Typography/Text';
import {
  OperationResult,
  OperationResultType
} from '@/common/models/OperationResult';

export type QrCodeReaderStatuses =
  | 'initializing'
  | 'camera'
  | 'loading'
  | 'complete'
  | 'error';

export interface QrCodeReaderProps {
  scanDelay?: number;
  containerProps?: Omit<BoxProps, '__vars'>;
  errorProps?: TextProps;
  onError?: (error: string) => void;
  onSuccess?: (text: string) => void;
  onCheckAsync: (result: string) => Promise<QrCodeReadResult>;
  onIsValidCallback?: () => void;
  infoContent?: ReactNode;
  qrBorderColor?: string;
  isValid: boolean;
  defaultText?: string;
  successIconSize?: number | string;
}

export class QrCodeReadResult extends OperationResult<
  OperationResultType,
  { code: string }
> {
  constructor(props: Partial<QrCodeReadResult>) {
    super(props);
    Object.assign(this, props);
  }

  static forSuccess(code: string) {
    return new QrCodeReadResult({
      result: OperationResultType.Success,
      success: true,
      data: { code }
    });
  }

  static forError(error: string) {
    return new QrCodeReadResult({
      result: OperationResultType.Failed,
      errorMessage: error,
      success: false
    });
  }
}
