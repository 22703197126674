import { FieldValues } from 'react-hook-form';

import { OptionModel } from '@/common/models/OptionModel';

import { IAmFormHookField } from '../../_FormHookField';
import { FormMultiSelect, FormMultiSelectProps } from './FormMultiSelect';

export interface FormHookMultiSelectProps<
  TOptionModel extends OptionModel = OptionModel,
  TFieldValues extends FieldValues = FieldValues
> extends IAmFormHookField<TFieldValues>,
    Omit<
      FormMultiSelectProps<TOptionModel>,
      'required' | 'name' | 'value' | 'defaultValue' | 'error' | 'disabled'
    > {}

export function FormHookMultiSelect<
  TOptionModel extends OptionModel = OptionModel,
  TFieldValues extends FieldValues = FieldValues
>(props: FormHookMultiSelectProps<TOptionModel, TFieldValues>) {
  const { hook, onBlur, onChange, ...rest } = props;
  return (
    <FormMultiSelect<TOptionModel>
      {...rest}
      required={hook.required}
      name={hook.field.name}
      ref={hook.field.ref}
      disabled={hook.disabled}
      onBlur={(e) => {
        hook.field.onBlur();
        onBlur?.(e);
      }}
      onChange={(e) => {
        hook.field.onChange(e);
        onChange?.(e);
      }}
      value={hook.field.value}
      error={hook.showError ? hook.errorMessage : undefined}
    />
  );
}
