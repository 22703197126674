import { KeyOf } from '@/common/models/KeyOf';

export interface FormHiddenFieldProps<TFormValues extends object = any> {
  name: KeyOf<TFormValues>;
  value: string | number;
}
export function FormHiddenField<TFormValues extends object = any>(
  props: FormHiddenFieldProps<TFormValues>
) {
  const { name, value, ...rest } = props;
  return (
    <input
      name={name as string}
      readOnly
      value={value || ''}
      {...rest}
      style={{ display: 'none' }}
    />
  );
}
