import { useMemo } from 'react';

import { sanitizeDynamicFormRendererFormValues } from '@/common/components/Form/DynamicForm/Renderer';
import { FormField } from '@/common/models/form';

export class SignUpFormHelper {
  private readonly _fields: FormField[];
  private readonly _initialValues: any;
  private readonly _hideInitialValuesFromForm: boolean;
  private readonly _passedInFieldsToShow: string[];

  constructor(
    fields: FormField[],
    initialValues?: any,
    hideInitialValuesFromForm?: boolean,
    passedInFieldsToShow?: string[]
  ) {
    this._fields = fields;
    this._initialValues = initialValues;
    this._hideInitialValuesFromForm = hideInitialValuesFromForm || false;
    this._passedInFieldsToShow = passedInFieldsToShow || [];
  }

  public resolveFields() {
    let fields = this._fields.filter((x) => x.isActive);
    if (this._hideInitialValuesFromForm && this._initialValues) {
      const fieldKeysToHide = Object.keys(this._initialValues).filter(
        (k) => !this._passedInFieldsToShow.some((x) => x === k)
      );
      fields = fields.filter((x) => !fieldKeysToHide.some((k) => k === x.name));
    }
    return fields;
  }

  public sanitiseValuesForSubmit(values: any) {
    return sanitizeDynamicFormRendererFormValues(values, this.resolveFields());
  }

  public shouldEmailBeDisabled() {
    if (!this._initialValues || !this._hideInitialValuesFromForm) {
      return false;
    }

    const fieldKeys = Object.keys(this._initialValues);
    return fieldKeys.some((x) => x === 'email');
  }
}

export const useSignUpFormHelper = (
  fields: FormField[],
  initialValues?: any,
  hideInitialValuesFromForm?: boolean,
  passedInFieldsToShow?: string[]
) => {
  return useMemo(
    () =>
      new SignUpFormHelper(
        fields,
        initialValues,
        hideInitialValuesFromForm,
        passedInFieldsToShow
      ),
    [fields, initialValues, hideInitialValuesFromForm, passedInFieldsToShow]
  );
};
