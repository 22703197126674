'use client';
import {
  Box as MantineBox,
  BoxProps as MantineBoxProps,
  createPolymorphicComponent
} from '@mantine/core';
import cx from 'clsx';
import { CSSProperties, forwardRef } from 'react';

/**
 * @see https://mantine.dev/core/box/
 */
export interface BoxProps extends MantineBoxProps, LegacyBoxStyles {}

export const Box = createPolymorphicComponent<'div', BoxProps>(
  forwardRef<HTMLDivElement, BoxProps>(
    (
      {
        overflow,
        margin,
        padding,
        maxWidth,
        className,
        __vars: __varsProp = {},
        ...rest
      },
      ref
    ) => {
      const __vars = __varsProp || {};
      __vars['--box-overflow'] = overflow ?? 'initial';

      return (
        <MantineBox
          m={margin}
          p={padding}
          maw={maxWidth}
          className={cx('mantine-Box', className)}
          __vars={__vars}
          {...rest}
          ref={ref}
        />
      );
    }
  )
);

/**
 * @deprecated use Box
 */
export const BoxV2 = Box;

interface LegacyBoxStyles {
  /**
   * @deprecated use maw
   */
  maxWidth?: MantineBoxProps['maw'];

  /**
   * @deprecated use m
   */
  margin?: MantineBoxProps['m'];

  /**
   * @deprecated use p
   */
  padding?: MantineBoxProps['p'];

  /**
   * @deprecated use css module
   */
  overflow?: CSSProperties['overflow'];
}
