import { useMemo } from 'react';

import { BoxProps } from '@/common/components/Display/Box';

export type UseKomoNavColorVarsOptions = {
  backgroundColor?: string;
  textColor?: string;
  textActiveColor?: string;
};

export const useKomoNavColorVars = (options: UseKomoNavColorVarsOptions) => {
  const { backgroundColor, textColor, textActiveColor } = options ?? {};

  return useMemo<BoxProps['__vars']>(() => {
    const vars: BoxProps['__vars'] = {};

    if (textColor) {
      vars['--komo-nav-c'] = textColor;
    }

    if (backgroundColor) {
      vars['--komo-nav-bg'] = backgroundColor;
    }

    if (textActiveColor) {
      vars['--komo-nav-active-c'] = textActiveColor;
    }

    return vars;
  }, [backgroundColor, textColor, textActiveColor]);
};
