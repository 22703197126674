import isString from 'lodash/isString';
import { FieldValues } from 'react-hook-form';

import { FormHookCheckboxV2 } from '../../FormCheckbox/V2';
import {
  CheckboxDynamicFieldOptions,
  DynamicFormContextData,
  IAmDynamicFormFieldProps
} from '../types';
import { DynamicFormHookField } from './__DynamicFormHookField';

export function DynamicFormCheckboxField<
  TFieldValues extends FieldValues = FieldValues,
  TContextData extends DynamicFormContextData = DynamicFormContextData
>({
  field,
  disabled,
  contextData,
  ...rest
}: IAmDynamicFormFieldProps<TFieldValues, TContextData>) {
  const { label, ...optionsRest } = (field.data.options ||
    {}) as CheckboxDynamicFieldOptions;

  const labelIsString = isString(label);

  return (
    <DynamicFormHookField<TFieldValues, TContextData>
      field={field}
      disabled={disabled}
      contextData={contextData}
    >
      {(h) => (
        <FormHookCheckboxV2
          hook={h}
          label={
            labelIsString
              ? {
                  type: 'Html',
                  html: label
                }
              : {
                  type: 'Content',
                  content: label
                }
          }
          {...optionsRest}
          {...rest}
        />
      )}
    </DynamicFormHookField>
  );
}
