import { FieldValues } from 'react-hook-form';

import { FormHookDateMaskInput } from '../../FormDateInput/FormHookDateMaskInput';
import {
  DateMaskDynamicFieldOptions,
  DynamicFormContextData,
  IAmDynamicFormFieldProps
} from '../types';
import { DynamicFormHookField } from './__DynamicFormHookField';

export function DynamicFormDateMaskField<
  TFieldValues extends FieldValues = FieldValues,
  TContextData extends DynamicFormContextData = DynamicFormContextData
>({
  field,
  disabled,
  contextData,
  ...rest
}: IAmDynamicFormFieldProps<TFieldValues, TContextData>) {
  return (
    <DynamicFormHookField<TFieldValues, TContextData>
      field={field}
      disabled={disabled}
      contextData={contextData}
    >
      {(h) => (
        <FormHookDateMaskInput
          hook={h}
          {...(field.data.options as DateMaskDynamicFieldOptions)}
          {...rest}
        />
      )}
    </DynamicFormHookField>
  );
}
