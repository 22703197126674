import { Guid } from '@/common/models/Guid';
import {
  FrontLiveSurveyGameplay,
  FrontLiveSurveyGameplayQuestion
} from '@/common/models/live-survey/Front';
import {
  addOrReplaceItemToArray,
  tryRemoveFromArray
} from '@/common/utils/ArrayFunctions';

export class LiveSurveyGameplayQuestionUpdatedEvent {
  cardId: Guid;
  gameplayId: Guid;
  userId: Guid;
  questionId: Guid;
  question?: FrontLiveSurveyGameplayQuestion;

  constructor(props: Partial<LiveSurveyGameplayQuestionUpdatedEvent>) {
    props = props || {};
    Object.assign(this, props);
    this.cardId = Guid.valueOrNew(props.cardId);
    this.gameplayId = Guid.valueOrNew(props.gameplayId);
    this.userId = Guid.valueOrNew(props.userId);
    this.questionId = Guid.valueOrNew(props.questionId);
    this.question = !!props.question
      ? new FrontLiveSurveyGameplayQuestion(props.question)
      : null;
  }

  canHandle(options: { cardId: Guid | string; gameplayId: Guid | string }) {
    return (
      this.cardId.equals(options.cardId) &&
      this.gameplayId.equals(options.gameplayId)
    );
  }

  applyChange(options: {
    gameplay: FrontLiveSurveyGameplay;
    onMapSuccess: (gameplay: FrontLiveSurveyGameplay) => void;
  }) {
    const newGameplay = new FrontLiveSurveyGameplay(options.gameplay);
    const { questionId } = this;
    if (!this.question) {
      tryRemoveFromArray(newGameplay.questions, (x) => x.id.equals(questionId));
    } else {
      addOrReplaceItemToArray(newGameplay.questions, this.question, (x) =>
        x.id.equals(questionId)
      );
    }

    options.onMapSuccess(newGameplay);
  }
}
