import { FieldValues } from 'react-hook-form';

import { DynamicFormSegmentedControlField } from '@/common/components/Form/DynamicForm/_Fields/_SegmentedControl';

import { DynamicFormBirthYearField } from './_Fields/_BirthYear';
import { DynamicFormCheckboxField } from './_Fields/_Checkbox';
import { DynamicFormColorField } from './_Fields/_Color';
import { DynamicFormCountryField } from './_Fields/_Country';
import { DynamicFormCurrencyField } from './_Fields/_Currency';
import { DynamicFormDateField } from './_Fields/_Date';
import { DynamicFormDateMaskField } from './_Fields/_DateMask';
import { DynamicFormDateTimeField } from './_Fields/_DateTime';
import { DynamicFormEmailField } from './_Fields/_Email';
import { DynamicFormFileField } from './_Fields/_File';
import { DynamicFormHiddenField } from './_Fields/_Hidden';
import { DynamicFormImageField } from './_Fields/_Image';
import { DynamicFormManualAddressField } from './_Fields/_ManualAddress';
import { DynamicFormMobileField } from './_Fields/_Mobile';
import { DynamicFormMultiSelectField } from './_Fields/_MultiSelect';
import { DynamicFormNativeSelectField } from './_Fields/_NativeSelect';
import { DynamicFormNumberField } from './_Fields/_Number';
import { DynamicFormPasswordField } from './_Fields/_Password';
import { DynamicFormPercentField } from './_Fields/_Percent';
import { DynamicFormQrCodeField } from './_Fields/_QrCode';
import { DynamicFormSelectField } from './_Fields/_Select';
import { DynamicFormTextField } from './_Fields/_Text';
import { DynamicFormTextAreaField } from './_Fields/_TextArea';
import { DynamicFormContextData, IAmDynamicFormFieldProps } from './types';

export function DynamicFormField<
  TFieldValues extends FieldValues = FieldValues,
  TContextData extends DynamicFormContextData = DynamicFormContextData
>({
  field,
  contextData,
  ...rest
}: IAmDynamicFormFieldProps<TFieldValues, TContextData>) {
  switch (field.data.type) {
    case 'BirthYear':
      return (
        <DynamicFormBirthYearField
          field={field}
          contextData={contextData}
          {...rest}
        />
      );
    case 'Checkbox':
      return (
        <DynamicFormCheckboxField
          field={field}
          contextData={contextData}
          {...rest}
        />
      );
    case 'Color':
      return (
        <DynamicFormColorField
          field={field}
          contextData={contextData}
          {...rest}
        />
      );
    case 'Country':
      return (
        <DynamicFormCountryField
          field={field}
          contextData={contextData}
          {...rest}
        />
      );
    case 'Currency':
      return (
        <DynamicFormCurrencyField
          field={field}
          contextData={contextData}
          {...rest}
        />
      );
    case 'Custom':
      return field.data.options.render({
        field,
        contextData,
        ...rest
      });
    case 'Date':
      return (
        <DynamicFormDateField
          field={field}
          contextData={contextData}
          {...rest}
        />
      );
    case 'DateMask':
      return (
        <DynamicFormDateMaskField
          field={field}
          contextData={contextData}
          {...rest}
        />
      );
    case 'DateTime':
      return (
        <DynamicFormDateTimeField
          field={field}
          contextData={contextData}
          {...rest}
        />
      );
    case 'Email':
      return (
        <DynamicFormEmailField
          field={field}
          contextData={contextData}
          {...rest}
        />
      );
    case 'File':
      return (
        <DynamicFormFileField
          field={field}
          contextData={contextData}
          {...rest}
        />
      );
    case 'Hidden':
      return (
        <DynamicFormHiddenField
          field={field}
          contextData={contextData}
          {...rest}
        />
      );
    case 'Image':
      return (
        <DynamicFormImageField
          field={field}
          contextData={contextData}
          {...rest}
        />
      );
    case 'ManualAddress':
      return (
        <DynamicFormManualAddressField
          field={field}
          contextData={contextData}
          {...rest}
        />
      );
    case 'Mobile':
      return (
        <DynamicFormMobileField
          field={field}
          contextData={contextData}
          {...rest}
        />
      );
    case 'MultiSelect':
      return (
        <DynamicFormMultiSelectField
          field={field}
          contextData={contextData}
          {...rest}
        />
      );
    case 'NativeSelect':
      return (
        <DynamicFormNativeSelectField
          field={field}
          contextData={contextData}
          {...rest}
        />
      );
    case 'Number':
      return (
        <DynamicFormNumberField
          field={field}
          contextData={contextData}
          {...rest}
        />
      );
    case 'Password':
      return (
        <DynamicFormPasswordField
          field={field}
          contextData={contextData}
          {...rest}
        />
      );
    case 'Percentage':
      return (
        <DynamicFormPercentField
          field={field}
          contextData={contextData}
          {...rest}
        />
      );
    case 'SegmentedControl':
      return (
        <DynamicFormSegmentedControlField
          field={field}
          contextData={contextData}
          {...rest}
        />
      );
    case 'Select':
      return (
        <DynamicFormSelectField
          field={field}
          contextData={contextData}
          {...rest}
        />
      );
    case 'Text':
      return (
        <DynamicFormTextField
          field={field}
          contextData={contextData}
          {...rest}
        />
      );
    case 'TextArea':
      return (
        <DynamicFormTextAreaField
          field={field}
          contextData={contextData}
          {...rest}
        />
      );
    case 'QrCode':
      return (
        <DynamicFormQrCodeField
          field={field}
          contextData={contextData}
          {...rest}
        />
      );
    default:
      return <div>[{(field.data as any).type}] Not yet implemented</div>;
  }
}
