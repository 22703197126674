import { produce } from 'immer';
import { useRouter } from 'next/router';
import { FC, useEffect, useState } from 'react';

import { SiteBadgeStateParameterName } from '@/common/components/Card/shared/Front/Routing/DrawerRouting';
import { Box } from '@/common/components/Display';
import { Image } from '@/common/components/Image';
import { ToastPosition, useToaster } from '@/common/components/Toast';
import { Title } from '@/common/components/Typography/Title';
import { useUser } from '@/common/components/UserContext';
import {
  SiteHubMethods,
  useSiteHubSubscribe
} from '@/common/data/signal-r/SiteHub';
import { useActionHandler } from '@/common/hooks/useActionHandler';
import { Guid } from '@/common/models/Guid';
import { ImageDataModel } from '@/common/models/ImageDataModel';
import { PublicContactsService } from '@/front/data/Contacts';
import { FrontBadgeView } from '@/front/models/FrontBadgeView';

import { ProfileDrawer } from './Drawer';
import classes from './HeaderBadgesButton.module.css';

type NotificationViewType = {
  title: string;
  image: ImageDataModel;
};

interface Props {
  siteId: Guid;
  badgeDrawerOpen: boolean;
}

const badgesToShow = 3;

export const HeaderBadgesButton: FC<Props> = ({ siteId, badgeDrawerOpen }) => {
  const [badges, setBadges] = useState<FrontBadgeView[]>([]);
  const router = useRouter();
  const toaster = useToaster();
  const { userId, profileId, lastUpdated } = useUser();
  const userLastUpdatedString = lastUpdated.toString();

  const handleOpenDrawer = () => {
    router.query[SiteBadgeStateParameterName] = 'true';
    router.replace(router, undefined, {
      shallow: true
    });
  };

  const handleCloseDrawer = () => {
    delete router.query[SiteBadgeStateParameterName];
    router.replace(
      { pathname: router.pathname, query: router.query },
      undefined,
      { shallow: true }
    );
  };

  const subscribe = useSiteHubSubscribe();

  const [handleAsync, { isHandling }] = useActionHandler();

  useEffect(() => {
    if (!siteId) return;
    handleAsync(
      async () => {
        const badgesPromise =
          PublicContactsService.actions.achievements.getAllBadgesAsync();

        const userAchievementsPromise =
          PublicContactsService.actions.achievements.getMineAsync();

        const [badges, userAchievements] = await Promise.all([
          badgesPromise,
          userAchievementsPromise
        ]);

        if (userAchievements.length > 0) {
          badges.forEach((badgeView) => {
            badgeView.tryAwardBadge(userAchievements);
          });
        }

        return badges;
      },
      {
        noToastOnError: true,
        onSuccess: setBadges
      }
    );
  }, [
    siteId.toString(),
    userId.toString(),
    profileId?.toString(),
    userLastUpdatedString
  ]);

  useEffect(() => {
    const unSub = subscribe(SiteHubMethods.OnAchievementAwarded, async (e) => {
      const newBadgesAwarded: NotificationViewType[] = [];

      setBadges(
        produce((data) => {
          data.forEach((badgeView) => {
            const earnedBadge = badgeView.badges.find((b) =>
              b.achievementId.equals(e.achievementId)
            );
            if (earnedBadge) {
              earnedBadge.awardBadge();
            }
            if (badgeView.isEarned().achievementId?.equals(e.achievementId)) {
              newBadgesAwarded.push({
                title: earnedBadge.title,
                image: earnedBadge.image
              });
            }
          });
        })
      );

      newBadgesAwarded.forEach((notificationView) => {
        toaster.pushNotification({
          title: (
            <Title order={6} size={'h6'}>
              BADGE EARNED
            </Title>
          ),
          message: (
            <Title order={5} size={'h5'}>
              {notificationView.title}
            </Title>
          ),
          image: notificationView.image,
          config: {
            position: ToastPosition.TopRight,
            autoHideTime: 10_000,
            onClick: handleOpenDrawer
          }
        });
      });
    });
    return () => unSub();
  }, []);

  if (isHandling || badges.length === 0) {
    return null;
  }

  const sortedBadges = [...badges].sort(
    (x, y) => Number(y.isEarned().earned) - Number(x.isEarned().earned)
  );

  return (
    <>
      <Box className={classes.container} onClick={handleOpenDrawer}>
        {sortedBadges.map((b, i) => {
          if (i > badgesToShow - 1) return null;
          const { earned, styling, achievementId } = b.isEarned();
          return (
            <Box
              key={earned ? achievementId.toString() : i}
              style={{
                zIndex: 7 - i,
                filter: 'drop-shadow(0.72px 0px 0.72px rgba(0, 0, 0, 0.25))'
              }}
              ml={i === 0 ? 0 : -14.8}
            >
              <Box
                data-active={earned || b.disableGrayscale}
                className={classes.icon}
              >
                <Image objectFit="contain" image={styling.image} />
              </Box>
            </Box>
          );
        })}
      </Box>
      <ProfileDrawer
        open={badgeDrawerOpen}
        onClose={handleCloseDrawer}
        badges={badges}
      />
    </>
  );
};
