import { FC, useEffect, useRef } from 'react';

import { Accordion } from '@/common/components/Accordion';
import { orderByMostVisibleThenByMostRecentDescending } from '@/common/components/Card/shared/VisibilityTracking/types';
import { Stack } from '@/common/components/Display';
import { FormGroup } from '@/common/components/Form/_shared/FormGroup';
import { Label } from '@/common/components/Label';
import { ListV2 } from '@/common/components/List';
import { ScrollArea } from '@/common/components/ScrollArea';
import { Text } from '@/common/components/Typography/Text';
import { useUser } from '@/common/components/UserContext';
import { FrontSessionV2MaxCollectTimeMs } from '@/common/models/user-sessions-v2/Front/FrontSessionV2MaxCollectTimeMs';
import { useIsDebug } from '@/front/components/komo-debug/KomoDebugContext';
import { KomoDebugPortal } from '@/front/components/komo-debug/KomoDebugPortal';
import { PublicSiteService } from '@/front/data/Sites';

import { useFrontSite } from '../SiteHost/Store';
import { useFrontSession } from './FrontSessionStore';
import { useIdleTimerStore } from './IdleTimerStore';

export const FrontSessionActivityPoller: FC = () => {
  const user = useUser();
  // don't start polling before session has inited
  const sessionV2 = useFrontSession((x) => x.session);

  const enabled = user.hasContact && !!sessionV2;

  if (!enabled) {
    return null;
  }

  return <InnerFrontSessionActivityPoller />;
};

const InnerFrontSessionActivityPoller: FC = () => {
  const siteId = useFrontSite((x) => x.site?.id);
  const pingTimerRef = useRef<NodeJS.Timeout>(null);
  const isIdle = useIdleTimerStore((x) => x.isIdle);
  const firstUpdate = useRef(true);

  useEffect(() => {
    const doPingAsync = () =>
      PublicSiteService.actions.tryCollectActivityAsync(siteId, { isIdle });

    const poll = () => {
      pingTimerRef.current = setTimeout(() => {
        void doPingAsync();
        if (!isIdle) {
          poll();
        }
      }, FrontSessionV2MaxCollectTimeMs);
    };

    clearTimeout(pingTimerRef.current);

    // do a ping when isIdle changes, but not the first time
    if (firstUpdate.current) {
      firstUpdate.current = false;
    } else {
      void doPingAsync();
    }

    // continue pinging while not idle
    if (!isIdle) {
      poll();
    }

    return () => clearTimeout(pingTimerRef.current);
  }, [isIdle, siteId]);

  useEffect(() => {
    if (!window) {
      return;
    }

    const onLeave = () => {
      void PublicSiteService.actions.tryCollectActivityAsync(siteId, {
        isIdle: true,
        useBeacon: true
      });
    };

    const leaveEvent = 'onpagehide' in window ? 'pagehide' : 'unload';
    window.addEventListener(leaveEvent, onLeave);

    return () => window.removeEventListener(leaveEvent, onLeave);
  }, []);

  const isDebug = useIsDebug();
  if (!isDebug) {
    return null;
  }

  return <ActivityPollerDebug />;
};

const ActivityPollerDebug: FC = ({}) => {
  const frontSessionStore = useFrontSession((x) => x);

  return (
    <KomoDebugPortal>
      <Accordion variant={'contained'}>
        <Accordion.Item value={'a'}>
          <Accordion.Control>SessionV2 store</Accordion.Control>
          <Accordion.Panel>
            <ScrollArea>
              <Stack gap={'xs'} mah={'400px'}>
                <FormGroup mb={0}>
                  <Label sizeVariant={'Small'}>Session V2 ID</Label>
                  {frontSessionStore.session?.id?.toString?.() || 'null'}
                </FormGroup>
                <FormGroup mb={0}>
                  <Label sizeVariant={'Small'}>
                    Focused Card ID (Debounced)
                  </Label>
                  {frontSessionStore.focusedCardId?.toString?.() || 'null'}
                </FormGroup>
                <div>
                  <Label sizeVariant={'Small'}>Visible cards</Label>
                  <ListV2>
                    <Text component="div" size={'xs'}>
                      {[...frontSessionStore.visibleCards]
                        .sort(orderByMostVisibleThenByMostRecentDescending)
                        .slice(0, 5)
                        .map((x) => (
                          <ListV2.Item key={x.cardId.toString()}>
                            {x.visibilityLevel}%: {x.cardId.toString()}
                          </ListV2.Item>
                        ))}
                    </Text>
                  </ListV2>
                </div>
              </Stack>
            </ScrollArea>
          </Accordion.Panel>
        </Accordion.Item>
      </Accordion>
    </KomoDebugPortal>
  );
};
