import { Guid } from '@/common/models/Guid';
import { FrontLiveSurveyQuestion } from '@/common/models/live-survey/Front';
import {
  addOrReplaceItemToArray,
  mapArray,
  tryRemoveFromArray
} from '@/common/utils/ArrayFunctions';

export class LiveSurveyQuestionUpdatedEvent {
  cardId: Guid;
  questionId: Guid;
  question?: FrontLiveSurveyQuestion;

  constructor(props: Partial<LiveSurveyQuestionUpdatedEvent>) {
    props = props || {};
    Object.assign(this, props);
    this.cardId = Guid.valueOrNew(props.cardId);
    this.questionId = Guid.valueOrNew(props.questionId);
    this.question = !!props.question
      ? new FrontLiveSurveyQuestion(props.question)
      : null;
  }

  canHandle(cardId: Guid | string) {
    return this.cardId.equals(cardId);
  }

  applyChange(existing: FrontLiveSurveyQuestion[]) {
    const newQuestions = mapArray(
      existing,
      (x) => new FrontLiveSurveyQuestion(x)
    );

    const { questionId } = this;
    if (!this.question) {
      tryRemoveFromArray(newQuestions, (x) => x.id.equals(questionId));
    } else {
      addOrReplaceItemToArray(newQuestions, this.question, (x) =>
        x.id.equals(questionId)
      );
    }

    return newQuestions;
  }
}
