import {
  DateInput as MantineDateInput,
  DateInputProps as MantineDateInputProps
} from '@mantine/dates';
import zonedTimeToUtc from 'date-fns-tz/zonedTimeToUtc';
import isNil from 'lodash/isNil';
import { forwardRef, useMemo } from 'react';

import {
  IAmInputRenderProps,
  InputRenderer
} from '@/common/components/Form/Renderers';
import { useUncontrolled } from '@/common/hooks/useUncontrolled';
import {
  getLocaleDateString,
  tryGetZonedDate
} from '@/common/utils/DateFunctions';

export interface FormDateInputV2Props
  extends Omit<
      MantineDateInputProps,
      'labelProps' | 'descriptionProps' | 'withAsterisk'
    >,
    Omit<IAmInputRenderProps<HTMLInputElement>, 'size'> {
  /**
   * Provide a time zone ID to use.
   * This will cause the saved value to be in UTC, but the displayed value will be in the given time zone.
   */
  timeZoneId?: string;
}

export const FormDateInputV2 = forwardRef<
  HTMLInputElement,
  FormDateInputV2Props
>(
  (
    {
      value: valueProp,
      defaultValue,
      required,
      onFocus,
      selectAllTextOnFocus,
      autoFocus,
      valueFormat: valueFormatProp,
      descriptionProps,
      inputContainer,
      showSkeleton,
      labelProps,
      error,
      hideErrorMessage,
      size,
      onChange,
      timeZoneId,
      placeholder,
      popoverProps: popoverPropsProp,
      ...rest
    },
    ref
  ) => {
    const popoverProps = popoverPropsProp ?? {};
    if (isNil(popoverProps.withinPortal)) {
      popoverProps.withinPortal = true;
    }

    if (popoverProps.withinPortal && isNil(popoverProps.zIndex)) {
      popoverProps.zIndex = 1100;
    }

    const [value, handleChange] = useUncontrolled({
      value: valueProp,
      defaultValue,
      finalValue: null,
      onChange
    });

    const pickerValue = useMemo(() => {
      if (!timeZoneId) {
        return value;
      }

      return tryGetZonedDate(value, timeZoneId);
    }, [value, timeZoneId]);

    const handlePickerChange = (v: Date) => {
      if (!timeZoneId) {
        handleChange(v);
      }

      return handleChange(zonedTimeToUtc(v, timeZoneId));
    };

    const valueFormat = useMemo(() => {
      return valueFormatProp || getLocaleDateString();
    }, [valueFormatProp]);
    return (
      <InputRenderer<HTMLInputElement>
        ref={ref}
        autoFocus={autoFocus}
        onFocus={onFocus}
        selectAllTextOnFocus={selectAllTextOnFocus}
        error={error}
        hideErrorMessage={hideErrorMessage}
        labelProps={labelProps}
        descriptionProps={descriptionProps}
        inputContainer={inputContainer}
        showSkeleton={showSkeleton}
        size={size}
      >
        {({ size: _, ...ip }, ref) => (
          <MantineDateInput
            ref={ref}
            value={pickerValue}
            onChange={handlePickerChange}
            withAsterisk={required}
            valueFormat={valueFormat}
            placeholder={placeholder || valueFormat.toUpperCase()}
            {...rest}
            {...ip}
            size={size}
            popoverProps={popoverProps}
          />
        )}
      </InputRenderer>
    );
  }
);
