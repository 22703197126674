import { Button } from '@/common/components/Button';
import { BurgerIcon } from '@/common/components/Icons/BurgerIcon';
import { useFlag } from '@/common/hooks/useFlag';
import { SiteMenu } from '@/common/models/site-menu/SiteMenu';
import { MenuDrawer } from '@/front/components/site/SiteHost/SiteHeader/MenuDrawer/MenuDrawer';

interface Props {
  menu: SiteMenu;
}

export const HeaderBurgerMenu = ({ menu }: Props) => {
  const menuOpen = useFlag(false);

  return (
    <>
      <Button
        variant="subtle"
        c="var(--komo-nav-c)"
        bg="var(--komo-nav-bg)"
        p={0}
        onClick={menuOpen.setTrue}
      >
        <BurgerIcon size="xl" />
      </Button>
      <MenuDrawer
        menu={menu}
        open={menuOpen.value}
        onClose={menuOpen.setFalse}
      />
    </>
  );
};
