import { memo } from 'react';

import { Stack } from '@/common/components/Display';
import { Center } from '@/common/components/Display/Center';
import { KomoLoader } from '@/common/components/Loader';
import { SkeletonV2 } from '@/common/components/Skeleton';

import { Modal } from './Modal';
import { ModalContent } from './ModalContent';
import { ModalFooter, ModalFooterActionButtons } from './ModalFooter';
import { ModalHeader } from './ModalHeader';
import { ModalSizes } from './ModalSizes';

type ModalSkeletonProps = {
  size?: ModalSizes;
  contentVariant?: 'default' | 'komo-loader';
  withFooter?: boolean;
};
export const ModalSkeleton = memo(
  ({
    size = ModalSizes.Small,
    contentVariant = 'default',
    withFooter = true
  }: ModalSkeletonProps) => (
    <Modal isOpen size={size}>
      <ModalHeaderSkeleton />
      <ModalContentSkeleton contentVariant={contentVariant} />
      {withFooter && (
        <ModalFooter>
          <ModalFooterActionButtons
            primaryButton={{
              showSkeleton: true,
              text: 'Submit'
            }}
          />
        </ModalFooter>
      )}
    </Modal>
  )
);

export const ModalHeaderSkeleton = memo(() => (
  <ModalHeader
    title={<SkeletonV2 h={30} w={150} />}
    showFeedback={false}
    preCloseContent={<SkeletonV2 h={30} w={30} mr="xl" />}
  />
));

export const ModalContentSkeleton = memo(
  ({
    contentVariant = 'default'
  }: Pick<ModalSkeletonProps, 'contentVariant'>) => (
    <ModalContent>
      {contentVariant === 'default' && (
        <Stack gap="0.5rem">
          <SkeletonV2 h={16} w="20%" />
          <SkeletonV2 h={16} w="60%" />
          <SkeletonV2 h={16} w="80%" />
          <SkeletonV2 h={80} w="100%" />
        </Stack>
      )}
      {contentVariant === 'komo-loader' && (
        <Center h="100%" w="100%" mih={200}>
          <KomoLoader flex="1 1 auto" />
        </Center>
      )}
    </ModalContent>
  )
);
