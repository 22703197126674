import { FC } from 'react';

import { IconProps } from '../Icons/IconProps';
import { IconSvg } from '../Icons/IconSvg';

export const TikTokIcon: FC<IconProps> = ({ color, ...rest }) => {
  return (
    <IconSvg {...rest}>
      <g clipPath="url(#clip0)" fill={color || 'currentColor'}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7.4078 0.913446C8.86371 0.310389 10.4241 0 12 0C13.5759 0 15.1363 0.310389 16.5922 0.913446C18.0481 1.5165 19.371 2.40042 20.4853 3.51472C21.5996 4.62902 22.4835 5.95189 23.0866 7.4078C23.6896 8.86371 24 10.4241 24 12C24 13.5759 23.6896 15.1363 23.0866 16.5922C22.4835 18.0481 21.5996 19.371 20.4853 20.4853C19.371 21.5996 18.0481 22.4835 16.5922 23.0866C15.1363 23.6896 13.5759 24 12 24C10.4241 24 8.86371 23.6896 7.4078 23.0866C5.95189 22.4835 4.62902 21.5996 3.51472 20.4853C2.40042 19.371 1.5165 18.0481 0.913446 16.5922C0.310389 15.1363 0 13.5759 0 12C0 10.4241 0.310389 8.86371 0.913446 7.4078C1.5165 5.95189 2.40042 4.62902 3.51472 3.51472C4.62902 2.40042 5.95189 1.5165 7.4078 0.913446ZM12 2.4C10.7393 2.4 9.49096 2.64831 8.32624 3.13076C7.16151 3.6132 6.10322 4.32033 5.21177 5.21177C4.32033 6.10322 3.6132 7.16151 3.13076 8.32624C2.64831 9.49096 2.4 10.7393 2.4 12C2.4 13.2607 2.64831 14.509 3.13076 15.6738C3.6132 16.8385 4.32033 17.8968 5.21177 18.7882C6.10322 19.6797 7.16151 20.3868 8.32624 20.8692C9.49096 21.3517 10.7393 21.6 12 21.6C13.2607 21.6 14.509 21.3517 15.6738 20.8692C16.8385 20.3868 17.8968 19.6797 18.7882 18.7882C19.6797 17.8968 20.3868 16.8385 20.8692 15.6738C21.3517 14.509 21.6 13.2607 21.6 12C21.6 10.7393 21.3517 9.49096 20.8692 8.32624C20.3868 7.16151 19.6797 6.10322 18.7882 5.21177C17.8968 4.32033 16.8385 3.6132 15.6738 3.13076C14.509 2.64831 13.2607 2.4 12 2.4Z"
          fill={color || 'currentColor'}
        />
        <path
          d="M18.2937 8.50544C17.4935 8.50544 16.7551 8.24038 16.1623 7.79316C15.4824 7.28044 14.9938 6.52849 14.8213 5.66173C14.7783 5.44753 14.7555 5.22649 14.7531 5H12.4672V11.2462L12.4644 14.6675C12.4644 15.5821 11.8688 16.3577 11.0431 16.6304C10.8036 16.7096 10.5447 16.7471 10.2751 16.7323C9.93121 16.7135 9.6089 16.6096 9.32874 16.442C8.7325 16.0855 8.32837 15.4386 8.31732 14.6986C8.30005 13.542 9.2351 12.5992 10.3907 12.5992C10.6189 12.5992 10.8379 12.6364 11.0431 12.704V10.997V10.3833C10.8267 10.3513 10.6066 10.3346 10.3839 10.3346C9.1189 10.3346 7.9359 10.8604 7.09014 11.8077C6.45096 12.5236 6.06752 13.4369 6.00841 14.3946C5.93094 15.6528 6.39123 16.8487 7.28412 17.7311C7.41525 17.8607 7.55308 17.981 7.69712 18.0919C8.46261 18.6806 9.39843 19 10.384 19C10.6066 19 10.8269 18.9835 11.0433 18.9515C11.964 18.815 12.8135 18.3936 13.4839 17.7308C14.3078 16.9166 14.7629 15.8357 14.7677 14.6852L14.7559 9.57613C15.1489 9.87931 15.5787 10.1302 16.0397 10.3248C16.757 10.6274 17.5175 10.7808 18.3001 10.7806V9.12082V8.50498C18.3007 8.50544 18.2942 8.50544 18.2937 8.50544Z"
          fill={color || 'currentColor'}
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="24" height="24" fill={color || 'currentColor'} />
        </clipPath>
      </defs>
    </IconSvg>
  );
};
