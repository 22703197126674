import { MantineSize } from '@mantine/core';
import { useMutation } from '@tanstack/react-query';
import React, { FC } from 'react';

import { CardHeader } from '@/common/components/Card';
import { SiteAuthState } from '@/common/components/Card/shared/Front/Routing';
import { Group } from '@/common/components/Display';
import { Image } from '@/common/components/Image';
import { useToaster } from '@/common/components/Toast';
import { Site } from '@/common/models/site';
import { SiteUser } from '@/common/models/SiteUser';
import { useFrontPage } from '@/front/components/site/SiteHost/Store';
import { PublicAuthService } from '@/front/data/Auth';

import { DrawerHeaderAuthButton } from '../Buttons';

interface Props {
  user: SiteUser;
  site: Site;
  state: SiteAuthState;
  onStateChange: (state: SiteAuthState) => void;
  onSignOutSuccess: (user: SiteUser) => void;
  size?: MantineSize;
}

export const AuthDrawerHeader: FC<Props> = ({
  user,
  site,
  state,
  size,
  onStateChange,
  onSignOutSuccess
}) => {
  const pageProperties = useFrontPage((x) => x.page?.properties);
  const headerImage =
    site.properties.AuthIcon ||
    (pageProperties ? pageProperties.BrandImage : site.properties.BrandImage);
  const hasImage = !!headerImage;
  const toaster = useToaster();

  const resolveTitle = () => {
    switch (state) {
      case SiteAuthState.SignIn:
        return site.properties.AuthSignInFormHeader;
      case SiteAuthState.SignUp:
        return site.properties.AuthSignUpFormHeader;
      case SiteAuthState.ForgotPassword:
        return site.properties.AuthRecoveryHeader;
      case SiteAuthState.Profile:
      default:
        return '';
    }
  };

  const { mutateAsync: handleSignOutAsync, isLoading } = useMutation(
    () => {
      return PublicAuthService.actions.signOutAsync(site.id);
    },
    {
      onSuccess: onSignOutSuccess,
      onError: (err) => {
        console.error(err);
        toaster.error('Failed to sign out, please try again');
      }
    }
  );

  const title = resolveTitle();
  const shouldShowButton =
    state !== SiteAuthState.None &&
    state !== SiteAuthState.SignUpConfirm &&
    state !== SiteAuthState.Verify;

  return (
    <>
      <Group
        gap={0}
        align="center"
        justify={hasImage ? 'space-between' : 'flex-end'}
      >
        {hasImage && (
          <Image
            image={headerImage}
            style={{ maxHeight: '40px', flex: '0 0' }}
          />
        )}
        {shouldShowButton && (
          <DrawerHeaderAuthButton
            onSignOut={handleSignOutAsync}
            disabled={isLoading}
            size={size}
            onStateChange={onStateChange}
            site={site}
            state={state}
            user={user}
          />
        )}
      </Group>
      {title && (
        <CardHeader variant="h2" mb="0.5rem" mt="1rem">
          {title}
        </CardHeader>
      )}
    </>
  );
};
