import { MantineSize } from '@mantine/core';
import React, { FC, useState } from 'react';

import { Anchor } from '@/common/components/Anchor';
import {
  DynamicFormHeaderSpacing,
  DynamicFormRendererHeader
} from '@/common/components/Form/DynamicForm/Renderer';
import { Text } from '@/common/components/Typography/Text';
import { Site } from '@/common/models/site';
import { SiteUser } from '@/common/models/SiteUser';
import { resolveAuthButtonProps, SignInForm } from '@/front/components/auth';
import { SignInConfirm } from '@/front/components/auth/SignIn/SignInConfirm';

interface Props {
  site: Site;
  onSignInSuccess: (user: SiteUser) => void;
  onForgotPasswordClick: () => void;
  onCancel: () => void;
  email?: string;
  size?: MantineSize;
}

export const AuthDrawerSignIn: FC<Props> = ({
  site,
  onSignInSuccess,
  onForgotPasswordClick,
  onCancel,
  email,
  size
}) => {
  const [confirmEmail, setConfirmEmail] = useState<string>(null);

  const submitButton = resolveAuthButtonProps(site, 'primary');

  const extraFormButtons = [
    resolveAuthButtonProps(site, 'secondary', {
      label: 'Cancel',
      onClick: onCancel
    })
  ];

  const hasPassword = !site.properties.AuthMagicLinkEnabled;
  return (
    <>
      {confirmEmail === null && (
        <>
          <Text mt={0} mb={DynamicFormHeaderSpacing}>
            {site.properties.AuthSignInFormSubText}
          </Text>

          <SignInForm
            size={size}
            siteId={site.id}
            usePassword={!site.properties.AuthMagicLinkEnabled}
            greetingMessage={site.properties.AuthSignInConfirmation}
            submitButton={resolveAuthButtonProps(site, 'primary')}
            extraButtons={extraFormButtons}
            onSuccess={onSignInSuccess}
            email={email}
            onConfirmEmail={setConfirmEmail}
          >
            {hasPassword && (
              <Anchor
                ta="center"
                c={submitButton.bgColor}
                onClick={onForgotPasswordClick}
              >
                Forgot your password?
              </Anchor>
            )}
          </SignInForm>
        </>
      )}
      {confirmEmail !== null && (
        <>
          <DynamicFormRendererHeader
            ta="left"
            title={site.properties.AuthSignInVerificationHeader}
            subTitle={site.properties.AuthSignInVerificationSubText}
          />
          <SignInConfirm
            site={site}
            size={size}
            onCancelSignIn={onCancel}
            onSignInSuccessConfirm={onSignInSuccess}
            email={confirmEmail}
            greetingMessage={site.properties.AuthSignInConfirmation}
          />
        </>
      )}
    </>
  );
};
