import dynamic from 'next/dynamic';
import { ForwardedRef, forwardRef, useMemo } from 'react';

import {
  AllCountryIso2Options,
  AllCountryNameOptions,
  CountryCodeOption
} from '@/common/utils/CountryCodes';

import { FormSelect, FormSelectProps } from '../FormSelect';

const FlagIcon = dynamic(() =>
  import('@/common/components/Icons/FlagIcon/FlagIcon').then((x) => x.FlagIcon)
);

export interface FormCountrySelectProps
  extends Omit<
    FormSelectProps,
    | 'leftSection'
    | 'valueIcon'
    | 'itemComponent'
    | 'data'
    | 'searchable'
    | 'limit'
  > {
  dataVariant?: 'default' | 'iso2';
  withFlags?: boolean;
  allowedCountries?: string[];
}

export const FormCountrySelect = forwardRef<
  HTMLInputElement,
  FormCountrySelectProps
>(
  (
    { dataVariant = 'default', allowedCountries, withFlags = true, ...rest },
    forwardedRef: ForwardedRef<HTMLInputElement>
  ) => {
    const data = useMemo(() => {
      const countries =
        dataVariant === 'default'
          ? AllCountryNameOptions
          : AllCountryIso2Options;
      return allowedCountries?.length
        ? countries.filter((x) => allowedCountries.includes(x.data.iso2))
        : countries;
    }, [dataVariant, allowedCountries]);

    return (
      <FormSelect<CountryCodeOption>
        searchable
        limit={999999}
        ref={forwardedRef}
        data={data}
        valueIcon={
          withFlags
            ? (v) => <FlagIcon size={24} code={v.data.iso2} />
            : undefined
        }
        itemComponent={
          withFlags
            ? {
                icon: (v) => <FlagIcon size={24} code={v.data.iso2} />
              }
            : undefined
        }
        {...rest}
      />
    );
  }
);
