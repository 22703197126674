import { DropEvent, FileRejection } from 'react-dropzone';

import {
  FormFileAllowedType,
  getFileType,
  getMimes
} from '@/common/utils/FileFunctions';

export const validateMime = (
  fileType: string,
  checking: FormFileAllowedType[]
) => {
  if (!checking) return null;
  if (!fileType) return `Must be of type: ${checking.join(', ')}`;

  const mimes = checking
    .map(getMimes)
    .flat(1)
    .filter((a) => a);
  if (!mimes.some((mime) => mime.toLowerCase() === fileType)) {
    return `Must be of type: ${checking.join(', ')}`;
  }
};

export const sanitiseDropEvent = (
  acceptedFiles: File[],
  fileRejections: FileRejection[],
  event: DropEvent,
  allowedTypes?: FormFileAllowedType[],
  validateFunc?: (file: File, event?: DropEvent) => string
) => {
  const errors: string[] = [];
  const accepted: File[] = [];
  if (acceptedFiles && acceptedFiles.length) {
    acceptedFiles.forEach((file) => {
      const tempError = validateFile(file, event, allowedTypes, validateFunc);
      if (tempError) {
        errors.push(tempError);
      } else {
        accepted.push(file);
      }
    });
  }

  const file = !accepted.length ? undefined : accepted[0];
  const type = !file ? undefined : getFileType(file);
  return {
    errors,
    file,
    type
  };
};

export const validateFile = (
  file: File,
  event?: DropEvent,
  allowedTypes?: FormFileAllowedType[],
  validateFunc?: (file: File, event?: DropEvent) => string
) => {
  const errors: string[] = [];
  if (validateFunc) {
    const tempError = validateFunc(file, event);
    if (tempError) errors.push(tempError);
  }

  if (allowedTypes && allowedTypes.length) {
    const tempError = validateMime(file.type, allowedTypes);
    if (tempError) {
      errors.push(tempError);
    }
  }

  return !errors.length ? null : `${file.name} - ${errors.join(', ')}`;
};
