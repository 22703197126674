import {
  Combobox as MantineCombobox,
  ComboboxProps as MantineComboboxProps,
  getFontSize as getMantineFontSize,
  getSpacing as getMantineSpacing,
  MantineSize,
  rem,
  useCombobox as useMantineCombobox
} from '@mantine/core';
import { useId as useMantineId } from '@mantine/hooks';
import { useQuery } from '@tanstack/react-query';
import cx from 'clsx';
import isNil from 'lodash/isNil';
import dynamic from 'next/dynamic';
import {
  forwardRef,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';

import { Group } from '@/common/components/Display/Group';
import {
  SelectOptions,
  SelectOptionsDropdown
} from '@/common/components/Form/FormSelect/_Components';
import {
  FormTextInput,
  FormTextInputProps
} from '@/common/components/Form/FormTextInput';
import { FlagProps } from '@/common/components/Icons/FlagIcon/FlagProps';
import { SearchIcon } from '@/common/components/Icons/SearchIcon';
import { useEvent } from '@/common/hooks/useEvent';
import { useForkRef } from '@/common/hooks/useForkRef';
import { useUncontrolled } from '@/common/hooks/useUncontrolled';
import {
  AllCountryIso2Options,
  CountryCode,
  CountryCodeOption
} from '@/common/utils/CountryCodes';
import {
  getLocationLookupCacheKey,
  PublicLocationService
} from '@/front/data/PublicLocationService';

import { Text } from '../../Typography/Text';
import classes from './FormMobileInput.module.css';

const FlagIcon = dynamic(() =>
  import('@/common/components/Icons/FlagIcon/FlagIcon').then((x) => x.FlagIcon)
);

const leftSectionSizes: Record<MantineSize, string> = {
  xs: rem(52.5),
  sm: rem(62.5),
  md: rem(70),
  lg: rem(80),
  xl: rem(95)
};

export type MobileValue = {
  dialCode: string;
  phone: string;
  iso2?: string;
};

const mobileCountries = AllCountryIso2Options.map((x) => ({
  ...x,
  label: `${x.data.name} | +${x.data.dialCode}`,
  data: {
    ...x.data,
    dialCode: `+${x.data.dialCode}`
  }
}));

// group mobileCountries by dialCode, find which ones have more than 1
const countryCodesByDialCode = mobileCountries.reduce<Record<string, string[]>>(
  (acc, item) => {
    if (!acc[item.data.dialCode]) {
      acc[item.data.dialCode] = [];
    }
    acc[item.data.dialCode].push(item.value);
    return acc;
  },
  {}
);

const multiCountriesPerDialCode = Object.keys(countryCodesByDialCode)
  .filter((x) => countryCodesByDialCode[x].length > 1)
  .reduce((acc, x) => {
    acc[x] = countryCodesByDialCode[x];
    return acc;
  }, {});

const getAllowedMobileCountries = (allowedCountries?: string[]) => {
  if (!allowedCountries?.length) return mobileCountries;
  return mobileCountries.filter((c) => allowedCountries.includes(c.value));
};

export const resolveMobileValue = (
  rawValue: string,
  allowedCountries?: string[]
): MobileValue => {
  if (!rawValue) {
    return { dialCode: '', iso2: '', phone: '' };
  }

  const splitPhone = rawValue.split(' ');
  if (splitPhone.length === 2) {
    const rawCode = splitPhone[0].startsWith('+')
      ? splitPhone[0]
      : `+${splitPhone[0]}`;

    const prefilledCountry = getAllowedMobileCountries(allowedCountries).find(
      (x) => x.data.dialCode === rawCode
    );

    if (prefilledCountry) {
      return {
        dialCode: prefilledCountry.data?.dialCode ?? '',
        iso2: prefilledCountry.data?.iso2 ?? '',
        phone: splitPhone[1]
      };
    }

    // else the prefilled country is not in the allowed countries, so the phone prefill is not valid
    return { dialCode: '', iso2: '', phone: '' };
  }

  return {
    dialCode: '',
    iso2: '',
    phone: rawValue
  };
};

export interface FormMobileInputProps
  extends Omit<
    FormTextInputProps,
    | 'inputMode'
    | 'autoComplete'
    | 'leftSection'
    | 'leftSectionPointerEvents'
    | 'type'
    | 'sanitiseValue'
    | 'onChange'
    | 'value'
    | 'defaultValue'
    | 'inputMode'
  > {
  value?: MobileValue;
  defaultValue?: MobileValue;
  onChange?: (value: MobileValue) => void;
  defaultIso2?: string;
  withFlags?: boolean;
  allowedCountries?: string[];
  countryComboboxProps?: MantineComboboxProps;
}

export const FormMobileInput = forwardRef<
  HTMLInputElement,
  FormMobileInputProps
>(
  (
    {
      id: idProp,
      onChange,
      value: valueProp,
      defaultValue,
      size = 'md',
      error,
      required,
      defaultIso2: defaultIso2Prop = 'AU',
      allowedCountries,
      disabled,
      w = '100%',
      className,
      countryComboboxProps: countryComboboxPropsProp,
      onFocus,
      ...rest
    },
    forwardedRef
  ) => {
    const [search, setSearch] = useState('');
    const inputRef = useRef<HTMLInputElement>();
    const inputRefAssign = useCallback((e: HTMLInputElement) => {
      inputRef.current = e;
    }, []);

    const ref = useForkRef(inputRefAssign, forwardedRef);
    useEffect(() => {
      if (combobox.dropdownOpened) {
        combobox.selectFirstOption();
      }
    }, [search]);

    const id = useMantineId(idProp);

    const countries = useMemo(() => {
      if (!allowedCountries?.length) return mobileCountries;
      return mobileCountries.filter((c) => allowedCountries.includes(c.value));
    }, [allowedCountries]);

    const countriesLookup = useMemo(
      () =>
        countries.reduce<Record<string, CountryCode>>((acc, item) => {
          acc[item.value] = item.data;
          return acc;
        }, {}),
      [countries]
    );

    let defaultCountry = countriesLookup[defaultIso2Prop];
    if (!defaultCountry) {
      defaultCountry =
        countriesLookup['AU'] ?? countriesLookup['US'] ?? countries[0]?.data;
    }

    const [value, handleChange] = useUncontrolled({
      value:
        valueProp !== undefined
          ? _sanitizeValue({
              countries,
              countriesLookup,
              defaultCountry,
              value: valueProp
            })
          : valueProp,
      sanitiseValue: (v) =>
        _sanitizeValue({
          countries,
          countriesLookup,
          defaultCountry,
          value: v
        }),
      defaultValue,
      onChange
    });

    const selectedCountry = countriesLookup[value?.iso2];

    const combobox = useMantineCombobox({
      onDropdownOpen: () => {
        setTimeout(() => {
          combobox.focusSearchInput();
          if (!!selectedCountry) {
            const index = (countries || []).findIndex(
              (x) => x.value === selectedCountry.iso2
            );
            if (index >= 0) {
              combobox.selectOption(index);
            } else {
              combobox.selectFirstOption();
            }
          } else {
            combobox.selectFirstOption();
          }
        }, 50);
      },
      onDropdownClose: () => {
        combobox.resetSelectedOption();
        setSearch('');
      }
    });

    _useLocationLookup({
      onChange: handleChange,
      countriesLookup,
      defaultCountry,
      value
    });

    const countryComboboxProps = countryComboboxPropsProp ?? {};
    if (isNil(countryComboboxProps.withinPortal)) {
      countryComboboxProps.withinPortal = true;
    }

    if (countryComboboxProps.withinPortal && !countryComboboxProps.zIndex) {
      //fix for showing above modals
      countryComboboxProps.zIndex = 1100;
    }

    if (isNil(countryComboboxProps.transitionProps)) {
      countryComboboxProps.transitionProps = {
        transition: 'pop'
      };
    }

    const handleInputFocus = useEvent<FormMobileInputProps['onFocus']>((e) => {
      combobox.closeDropdown();
      onFocus?.(e);
    });

    return (
      <MantineCombobox
        store={combobox}
        width="target"
        disabled={disabled}
        onOptionSubmit={(v) => {
          handleChange({
            phone: value?.phone ?? '',
            dialCode: countriesLookup[v]?.dialCode ?? '',
            iso2: countriesLookup[v]?.iso2 ?? ''
          });
          setSearch(v);
          combobox.closeDropdown();
          inputRef.current.focus();
        }}
        {...countryComboboxProps}
      >
        <MantineCombobox.Target withAriaAttributes={false}>
          <FormTextInput
            ref={ref}
            className={cx(className, classes.root)}
            id={id}
            {...rest}
            w={w}
            value={value?.phone ?? ''}
            type="tel"
            size={size}
            autoComplete="tel"
            inputMode="tel"
            required={required}
            error={error}
            leftSectionPointerEvents="all"
            leftSectionWidth={leftSectionSizes[size]}
            disabled={disabled}
            onFocus={handleInputFocus}
            onChange={(_, v) =>
              handleChange({
                phone: v,
                dialCode: value?.dialCode ?? defaultCountry?.dialCode ?? '',
                iso2: value?.iso2 ?? defaultCountry?.iso2 ?? ''
              })
            }
            leftSection={
              <_LeftSection
                size={size}
                onClick={() => combobox.toggleDropdown()}
                value={selectedCountry}
              />
            }
          />
        </MantineCombobox.Target>
        <SelectOptionsDropdown<CountryCodeOption>
          data={countries}
          labelId={`${id}-label`}
          withScrollArea
          nothingFoundMessage="No countries found"
          maxDropdownHeight={250}
          searchProps={{
            value: search,
            leftSection: <SearchIcon />,
            placeholder: 'Search countries...',
            onChange: (e) => {
              setSearch(e.currentTarget.value);
            }
          }}
        >
          {(_data) => (
            <SelectOptions<CountryCodeOption>
              data={_data}
              component={{
                icon: (x) => (
                  <_FlagIcon
                    value={x.data}
                    size={`calc(${getMantineFontSize(size)} * 1.1)`}
                  />
                ),
                title: (x) => (
                  <Group
                    gap="0.3rem"
                    justify="space-between"
                    fz="inherit"
                    align="center"
                  >
                    <Text inherit>{x.data.name}</Text>
                    <Text style={{ wordBreak: 'keep-all' }} inherit>
                      {x.data.dialCode}
                    </Text>
                  </Group>
                )
              }}
              value={value?.iso2}
              size={size}
            />
          )}
        </SelectOptionsDropdown>
      </MantineCombobox>
    );
  }
);

interface SanitizeValueOptions {
  value?: MobileValue;
  countriesLookup: Record<string, CountryCode>;
  countries: CountryCodeOption[];
  defaultCountry?: CountryCode;
}

const _sanitizeValue = ({
  countries,
  countriesLookup,
  defaultCountry,
  value
}: SanitizeValueOptions) => {
  {
    if (!value) {
      return {
        phone: '',
        dialCode: defaultCountry.dialCode,
        iso2: defaultCountry.iso2
      };
    }
    const iso2Lookup = countriesLookup[value?.iso2];
    const hasIso2 = !!value?.iso2;
    const hasDialCode = !!value?.dialCode;
    if (hasIso2 && hasDialCode && iso2Lookup?.dialCode === value.dialCode) {
      return value;
    }

    const sanitized: MobileValue = {
      phone: value?.phone ?? '',
      dialCode: value?.dialCode ?? '',
      iso2: value?.iso2 ?? ''
    };
    if (hasDialCode) {
      const found = countries.find((x) => x.data.dialCode === value.dialCode);
      if (found) {
        sanitized.iso2 = found.data.iso2;
        return sanitized;
      }
    }

    if (!countriesLookup[sanitized.iso2]) {
      sanitized.iso2 = defaultCountry.iso2;
      sanitized.dialCode = defaultCountry.dialCode;
    }

    return sanitized;
  }
};

interface UseLocationLookupProps {
  value: FormMobileInputProps['value'];
  onChange: FormMobileInputProps['onChange'];
  countriesLookup: Record<string, CountryCode>;
  defaultCountry?: CountryCode;
}

const _useLocationLookup = ({
  countriesLookup,
  onChange,
  value,
  defaultCountry
}: UseLocationLookupProps) => {
  useQuery(
    getLocationLookupCacheKey(),
    async () => {
      const { countryCode } = await PublicLocationService.lookupLocation();
      const found = countriesLookup[countryCode];
      if (
        found &&
        (!value.iso2 ||
          value.iso2 === defaultCountry.iso2 ||
          value.dialCode === found.dialCode)
      ) {
        onChange({
          phone: value?.phone ?? '',
          iso2: found.iso2,
          dialCode: found.dialCode
        });
      }
      return countryCode;
    },
    {
      enabled:
        !value?.dialCode ||
        defaultCountry.dialCode === value.dialCode ||
        !!multiCountriesPerDialCode[value.dialCode],
      refetchOnWindowFocus: false,
      refetchOnReconnect: false
    }
  );
};
interface _LeftSectionProps {
  value: CountryCode;
  size: MantineSize;
  onClick: () => void;
}
const _LeftSection = ({ onClick, value, size }: _LeftSectionProps) => {
  return (
    <Group
      role="button"
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
        onClick();
      }}
      gap={`calc(${getMantineSpacing(size)} * 0.1)`}
      align="center"
      fz={size}
      style={{ userSelect: 'none' }}
    >
      <_FlagIcon
        value={value}
        size={`calc(${getMantineFontSize(size)} * 1.5)`}
      />
      {value.dialCode}
    </Group>
  );
};
interface _FlagIconProps extends Omit<FlagProps, 'code'> {
  value: CountryCode;
}
const _FlagIcon = ({ value, ...rest }: _FlagIconProps) => {
  if (!value?.iso2) {
    return null;
  }
  return <FlagIcon code={value.iso2} {...rest} />;
};
