import { FieldValues } from 'react-hook-form';

import { BirthdayIcon } from '@/common/components/Icons/BirthdayIcon';
import { useConstant } from '@/common/hooks/useConstant';

import { FormHookNumberInput } from '../../FormNumberInput';
import {
  BirthYearDynamicFieldOptions,
  DynamicFormContextData,
  IAmDynamicFormFieldProps
} from '../types';
import { DynamicFormHookField } from './__DynamicFormHookField';

export function DynamicFormBirthYearField<
  TFieldValues extends FieldValues = FieldValues,
  TContextData extends DynamicFormContextData = DynamicFormContextData
>({
  field,
  disabled,
  contextData,
  ...rest
}: IAmDynamicFormFieldProps<TFieldValues, TContextData>) {
  const { min, max } = useConstant(() => resolveMinMax());

  const {
    withIcon = true,
    placeholder = 'YYYY',
    ...optionsRest
  } = (field.data.options as BirthYearDynamicFieldOptions) || {};

  return (
    <DynamicFormHookField<TFieldValues, TContextData>
      field={field}
      disabled={disabled}
      contextData={contextData}
    >
      {(h) => (
        <FormHookNumberInput
          hook={h}
          {...rest}
          {...optionsRest}
          inputMode="numeric"
          hideControls
          allowDecimal={false}
          placeholder={placeholder}
          min={min}
          max={max}
          leftSection={withIcon ? <BirthdayIcon /> : undefined}
        />
      )}
    </DynamicFormHookField>
  );
}

const resolveMinMax = () => {
  const max = new Date().getFullYear();
  return { max, min: max - 100 };
};
